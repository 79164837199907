import {InputLabel, Link, MenuItem, Select, SelectChangeEvent, SxProps, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

export function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.hellozaia.com/">
                ZAIA
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export interface ValueLabel {
    value: string
    label: string
}

export interface SelectWithDataProps {
    id: string;
    label: string;
    isOpen: boolean;
    selected?: number;
    data: ValueLabel[];
    onSelect?: (item: ValueLabel) => void;
}

export const SelectWithData: React.FC<SelectWithDataProps & { sx?: SxProps }> = ({
                                                                                     id,
                                                                                     label,
                                                                                     isOpen,
                                                                                     data,
                                                                                     selected = 0,
                                                                                     onSelect,
                                                                                     sx
                                                                                 }) => {

    const [selectedValue, setSelectedValue] = useState<ValueLabel>({value: "", label: ""})

    const handleChange = (event: SelectChangeEvent) => {
        const {value} = event.target;
        const selectedItem = data.find((item) => item.value === value);
        if (selectedItem && onSelect) {
            onSelect(selectedItem);
        }
        setSelectedValue(selectedItem || {value: '', label: ''});
    };


    useEffect(() => {
        console.log("In SelectWithData - UseEffect")
        console.log(data)
        setSelectedValue(data[selected])
    }, [])


    return (
        <Box sx={sx}>
            <InputLabel sx={sx} id={`label_${id}`}>{label}</InputLabel>
            <Select
                labelId={id}
                size={"small"}
                id={id}
                sx={sx}
                value={selectedValue.value}
                onChange={handleChange}
            >

                {data.map(item => (
                    <MenuItem key={`item_${item.value}_${item.label}`} value={item.value}>{item.label}</MenuItem>
                ))}

            </Select>
        </Box>
    )
}

