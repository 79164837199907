import React, {useCallback, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {NavBar} from "../components/navigation/NavComponent";
import {LogIn} from "../components/authorisation/LoginComponent";
import {LogOut} from "../components/authorisation/LogOutComponent";
import {
    Application,
    getApplications,
    getOrganisations,
    getProjects,
    Organisation,
    Project
} from "../backend/core.services";
import {
    setApplicationList,
    setCurrentApplication,
    setCurrentOrganization,
    setCurrentProject,
    updateAccessToken,
    updateSelectedLanguage
} from "../components/managers/generalManager";

export default function RootLayout() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLogInRequest, setIsLogInRequest] = useState(false);
    const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('BoostrFoundation_AccessToken'))
    const [showLogin, setShowLogin] = useState<boolean>(false)
    const [projectId, setProjectId] = useState<string>("")
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        let selected_organisation: Organisation;
        let selected_application: Application;
        let selected_project: Project;

        console.log("Loading RootLayout...")

        if (accessToken) {
            let loaded_project = JSON.parse(localStorage.getItem('BoostrFoundation_SelectedProject') || '{}');
            let loaded_application = JSON.parse(localStorage.getItem('BoostrFoundation_SelectedApplication') || '{}');
            let loaded_organisation = JSON.parse(localStorage.getItem('BoostrFoundation_SelectedOrganisation') || '{}');
            let loaded_language = (localStorage.getItem('BoostrFoundation_SelectedLanguage') || 'nl');

            updateAccessToken(accessToken);
            updateSelectedLanguage(loaded_language);

            // check if loaded project is empty
            if (Object.keys(loaded_organisation).length === 0 || Object.keys(loaded_application).length === 0 || Object.keys(loaded_project).length === 0) {

                getOrganisations().then((response: Organisation[]) => {
                    if (response.length === 0) {
                        console.log("No organisations found. Please login");
                        localStorage.removeItem('BoostrFoundation_AccessToken');
                        setShowLogin(true)
                    } else {
                        selected_organisation = response[0];
                        setCurrentOrganization(selected_organisation.uuid);

                        getApplications(selected_organisation.uuid).then((response: Application[]) => {
                            if (response.length === 0) {
                                console.log("No applications found. Please contact support");
                            }

                            // Store all applications in the system
                            setApplicationList(response);

                            selected_application = response[0];
                            getProjects(selected_organisation.uuid, selected_application.uuid).then((response: Project[]) => {
                                if (response.length === 0) {
                                    console.log("No projects found. Please contact support");
                                } else {
                                    selected_project = response[0];
                                    setCurrentProject(selected_project.uuid)

                                    localStorage.setItem('BoostrFoundation_SelectedOrganisation', JSON.stringify(selected_organisation));
                                    localStorage.setItem('BoostrFoundation_SelectedApplication', JSON.stringify(selected_application));
                                    localStorage.setItem('BoostrFoundation_SelectedProject', JSON.stringify(selected_project));
                                    setProjectId(selected_project.uuid);
                                    setCurrentProject(selected_project.uuid);
                                }

                                setIsLoaded(true);
                                setIsAuthenticated(true);
                            })
                        })
                    }
                })
            } else {
                console.log("Loaded details from local storage")

                getOrganisations().then((response: Organisation[]) => {
                    if (response.length === 0) {
                        console.log("No organisations found. Please login");
                        localStorage.removeItem('BoostrFoundation_AccessToken');
                        localStorage.removeItem("BoostrFoundation_SelectedOrganisation");
                        localStorage.removeItem("BoostrFoundation_SelectedApplication");
                        localStorage.removeItem("BoostrFoundation_SelectedProject");
                        setAccessToken(null);
                        setShowLogin(true)
                    } else {
                        selected_application = loaded_application;
                        selected_organisation = loaded_organisation;
                        selected_project = loaded_project;
                        setProjectId(selected_project.uuid);
                        setCurrentProject(selected_project.uuid);
                        setCurrentOrganization(selected_organisation.uuid)
                        setCurrentApplication(selected_application.uuid)
                    }
                    setIsLoaded(true);
                    setIsAuthenticated(true);
                });
            }
        } else {
            setShowLogin(true);
            setIsLoaded(false);
        }
    }, [accessToken])


// Menu option interaction
    const handleLogIn = useCallback(() => {
        setIsLogInRequest(true);
    }, []);

    const handleLogOut = useCallback(() => {
        setIsAuthenticated(false);
        setIsLoaded(false);
        localStorage.removeItem('BoostrFoundation_AccessToken');
        localStorage.removeItem("BoostrFoundation_SelectedOrganisation");
        localStorage.removeItem("BoostrFoundation_SelectedApplication");
        localStorage.removeItem("BoostrFoundation_SelectedProject");
        window.location.reload();

    }, []);

// LOG-IN / LOG-OUT component interaction
    const onLogInSuccess = useCallback((token: string) => {
        setShowLogin(false);
        setAccessToken(token);
    }, []);

    const onLogInCancel = useCallback(() => {
        setIsLogInRequest(false);
    }, []);

    return (

        <>
            {showLogin && (
                <LogIn
                    isOpen={showLogin}
                    onSucces={onLogInSuccess}
                    onCancel={onLogInCancel}
                />
            )}

            {isLoaded && (
                <div>
                    <NavBar isAuthenticated={isAuthenticated} onLogOutRequested={handleLogOut}
                            onLogInRequested={handleLogIn}/>

                    <main>
                        <Outlet/>
                    </main>

                </div>
            )}
        </>
    )
}