// import Paper, { PaperProps } from "@mui/material/Paper";
// import Draggable from "react-draggable";
// import React, { useRef } from "react";
// import {SxProps} from "@mui/material";
// import { Theme } from '@mui/material/styles';
// import {merge} from "lodash";
//
// type ExtendedPaperProps = PaperProps & {
//   styleOverrides?: SxProps<Theme>; // Add an optional styleOverrides prop
// };
//
// const defaultDraggablePaperStyle: SxProps<Theme> = {
//   // top: '10%',
//   // left: '10%',
//   bgcolor: 'background.paper',
//   minWidth: '80vw',
//   border: '2px solid #000',
//   boxShadow: 24,
//   resize: "both",
//   p: 4,
// };
//
// export const DraggablePaper = ({ styleOverrides, ...props }: ExtendedPaperProps): JSX.Element => {
//   const paperRef = useRef<HTMLDivElement>(null);
//
//   const draggablePaperStyle = merge({}, defaultDraggablePaperStyle, styleOverrides); // Merge default styles with overrides
//
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//       nodeRef={paperRef}
//     >
//       <Paper {...props} sx={draggablePaperStyle} ref={paperRef} />
//     </Draggable>
//   );
// };

import {PaperProps} from "@mui/material";
import {useRef} from "react";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { SxProps } from '@mui/system';

const draggablePaperStyle = {
  // top: '10%',
  // left: '10%',
  bgcolor: 'background.paper',
  minWidth: '40vw',
  border: '2px solid #000',
  boxShadow: 24,
  resize: "both",
  p: 4,
};

export const DraggablePaper = (props: PaperProps): JSX.Element => {
  const paperRef = useRef<HTMLDivElement>(null);

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={paperRef}
    >
      <Paper {...props} sx={draggablePaperStyle} ref={paperRef} />
    </Draggable>
  );
};

// interface DraggablePaperProps extends PaperProps {
//   sx?: SxProps;
// }
//
// export const DraggablePaper = (props: DraggablePaperProps): JSX.Element => {
//   const paperRef = useRef<HTMLDivElement>(null);
//
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//       nodeRef={paperRef}
//     >
//       <Paper {...props} sx={{ ...draggablePaperStyle, ...props.sx }} ref={paperRef} />
//     </Draggable>
//   );
// };