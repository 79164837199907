import React from "react";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab_panel-${index}`}
            aria-labelledby={`tab_panel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{marginTop: '10px'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index: number) {
    return {
        id: `tab_panel-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export {}
