import React, {useEffect, useState} from "react";
import {ExtDataRecord, getExtDataRecordList, QueryResult, runQuery} from "../../backend/extdata.services";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Tabs,
    AlertTitle, Alert, Select, MenuItem
} from "@mui/material";
import Box from "@mui/material/Box";
import {DraggablePaper} from "../general/Styling.snippets";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Candidate, findCandidates, findJobs,
    JobDescription, QueryForCandidates, QueryForJobDescriptions, runGPTRequest
} from "../../backend/recruitement.services";
import Tab from '@mui/material/Tab';
import {a11yProps, TabPanel, TabPanelProps} from "../general/TabelPanel.snippets";
import SimpleList from "../general/SimpleList";
import AlertDialog from "../general/AlertDialog";
import {colorSchema} from "../../data/theme";
import {stringify} from "querystring";


//=================================================================================================================
// <RecruitmentApp/> COMPONENT
//=================================================================================================================
export interface RecruitmentAppProps {
    projectId: string;
    initialTab: number;

    jobDescriptionList: JobDescription[];
    candidateList: Candidate[]

    onJobDescriptionAdded: (data_type: string, data: JobDescription | Candidate) => void;
    onCandidateAdded: (title: string, file: File | null) => void;

    onDeleted: (data_type: string, record_uuid: string) => void;
    onUpdate: (data_type: string, data: JobDescription | Candidate) => void;
    onRefresh: (data_type: string, tab: number) => void;
}

// ---------------------------------------------------------------------------------------------------------------
export function RecruitmentApp(props: RecruitmentAppProps) {

    const [jobDescriptionList, setJobDescriptionList] = useState<JobDescription[]>([]);
    const [candidateList, setCandidateList] = useState<Candidate[]>([]);
    const [tab, setTab] = React.useState(props.initialTab);
    const [showDialog, setShowDialog] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showRegisterCandidate, setShowRegisterCandidate] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [language, setLanguage] = useState<string>(() => localStorage.getItem('settings_language') || '');
    const [minScoreExpected, setMinScoreExpected] = useState<number>(() => {
        const value = localStorage.getItem('settings_minScoreExpected');
        return value ? Number(value) : 0;
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedMode, setSelectedMode] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedId, setSelectedId] = useState("");
    const [selectedDataType, setSelectedDataType] = useState("");

    useEffect(() => {
        setJobDescriptionList(props.jobDescriptionList.sort((a, b) => a.title.localeCompare(b.title)));
        setCandidateList(props.candidateList.sort((a, b) => a.title.localeCompare(b.title)));
        setIsLoaded(true);
    }, [])


    // Switch view (job descriptions or candidates) on request
    const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
        setTab(newTab);
    };

    useEffect(() => {
        // Update localStorage when state changes
        localStorage.setItem('settings_language', language);
        localStorage.setItem('settings_minScoreExpected', minScoreExpected.toString());
    }, [language, minScoreExpected]);

    // function handleSaveSettings() {
    //     localStorage.setItem('settings_language', language);
    //     localStorage.setItem('settings_minScoreExpected', minScoreExpected.toString());
    // }

    // Handle CRUD request, initiate corresponding dialog
    function HandleDialogRequest(mode: string, data_type: string, uuid: string = "", index: number = -1) {
        setSelectedMode(mode)
        setSelectedId(uuid)
        setSelectedDataType(data_type)
        setSelectedIndex(index)

        console.log(`CRUD request: mode:${mode}, type:${data_type}, uuid:${uuid}, index: ${index}`)

        if (mode === 'refresh') {
            props.onRefresh(data_type, tab)

        } else if (mode === 'add' && data_type === 'candidate') {

            // Open ADD CANDIDATE dialog
            setShowRegisterCandidate(true)

        } else if (mode === 'query') {
            setShowQuery(true);

        } else if (mode === 'search') {

            // Open SEARCH dialog
            setShowSearch(true);
        } else if (mode === 'delete') {

            // open DELETE CONFIRMATION dialog
            setShowConfirmation(true);
        } else {

            // OTHERWISE, use common and default DIALOG
            setShowDialog(true);
        }
    }

    function handleConfirmation(isConfirmed: boolean) {
        if (isConfirmed) {
            props.onDeleted(selectedDataType, selectedId);
        }
        setShowConfirmation(false);
    }

    // Close CRUD dialog at close
    function handleDialogClose() {
        setSelectedMode("");
        setSelectedId("");
        setSelectedDataType("");

        setShowDialog(false);
        setShowRegisterCandidate(false);
        setShowSearch(false);
        setShowQuery(false);
    }


    // JSX.element
    return (
        <Box>

            {/* CONFIRM DIALOG @ DELETE CANDIDATES or JOB DESCRIPTIONS */}
            {showConfirmation && (
                <AlertDialog open={true}
                             title={`Delete ${selectedMode} `}
                             message={`Please confirm the deletion of the selected ${selectedMode}`}
                             onSubmit={handleConfirmation}/>
            )}

            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Job Descriptions" {...a11yProps(0)} />
                        <Tab label="Candidates" {...a11yProps(1)} />
                        <Tab label="Settings" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                {/* JOB DESCRIPTION PANEL */}
                <TabPanel value={tab} index={0}>
                    <Grid container marginTop={1}>
                        {isLoaded && (
                            <SimpleList
                                onRequest={HandleDialogRequest}
                                data={jobDescriptionList.map(record => ({
                                    title: record.title,
                                    uuid: record.uuid,
                                    status: record.status
                                }))}
                                data_type="job_description"
                                has_search={true}
                                projectId={props.projectId}/>
                        )}
                    </Grid>
                </TabPanel>

                {/* CANDIDATE LIST PANEL */}
                <TabPanel value={tab} index={1}>
                    <TabPanel value={tab} index={1}>
                        <Grid container marginTop={1}>
                            {isLoaded && (
                                <SimpleList
                                    onRequest={HandleDialogRequest}
                                    data={candidateList.map(record => ({
                                        title: record.title,
                                        uuid: record.uuid,
                                        status: record.status
                                    }))}
                                    data_type="candidate"
                                    has_search={true}
                                    projectId={props.projectId}/>
                            )}

                        </Grid>
                    </TabPanel>
                </TabPanel>

                {/* SETTINGS PANEL */}
                <TabPanel value={tab} index={2}>
                    <TabPanel value={tab} index={2}>

                        {/* Select Language */}
                        <Grid container direction={"row"} alignItems={"center"} spacing={2} marginTop={1}>
                            <Grid item>
                                <Typography>Text generation language..:</Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    labelId="select_generator_language"
                                    size={"small"}
                                    variant={"outlined"}
                                    id="select_generator_language"
                                    value={language}
                                    label="language"
                                    onChange={(event) => {
                                        setLanguage(event.target.value);
                                    }}
                                >
                                    <MenuItem value={'nl'}>Nederlands</MenuItem>
                                    <MenuItem value={'fr'}>Français</MenuItem>
                                    <MenuItem value={'en'}>English</MenuItem>
                                    <MenuItem value={'de'}>Deutsch</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        {/* Select Min Score */}
                        <Grid container direction={"row"} alignItems={"center"} spacing={2} marginTop={1}>
                            <Grid item>
                                <Typography>The minimum matching score for the AI algorithm..:</Typography>
                            </Grid>
                            <Grid item>
                                <TextField size={"small"} value={minScoreExpected}
                                           inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}} onChange={(event) => {
                                    setMinScoreExpected(parseInt(event.target.value));
                                }}/>
                            </Grid>
                        </Grid>

                        {/*<Grid container direction={"row"} alignItems={"center"} spacing={2} marginTop={1}>*/}
                        {/*    <Button variant={"contained"} autoFocus onClick={handleSaveSettings}>Save Settings</Button>*/}
                        {/*</Grid>*/}

                    </TabPanel>
                </TabPanel>
            </Box>
            <Box>
                {(showDialog && selectedDataType === 'job_description') && (
                    <JobDescriptionDetail
                        data={selectedIndex >= 0 ? jobDescriptionList[selectedIndex] : {
                            uuid: "",
                            status: "",
                            processed: "",
                            title: "",
                            original: ""
                        }}
                        data_type={selectedDataType}
                        onSubmit={selectedMode === 'add' ? props.onJobDescriptionAdded : props.onUpdate}
                        mode={selectedMode}
                        onClose={handleDialogClose}
                    />
                )}

                {(showDialog && selectedDataType === 'candidate') && (
                    <CandidateDetail
                        data={selectedIndex >= 0 ? candidateList[selectedIndex] : {
                            uuid: "",
                            status: "",
                            processed: "",
                            title: "",
                            original: ""
                        }}
                        data_type={selectedDataType}
                        onSubmit={selectedMode === 'add' ? props.onJobDescriptionAdded : props.onUpdate}
                        mode={selectedMode}
                        onClose={handleDialogClose}
                    />
                )}

                {showRegisterCandidate && (
                    <CandidateForm
                        onSubmit={props.onCandidateAdded}
                        onClose={handleDialogClose}
                    />
                )}


                {showSearch && <SearchMatchData
                    projectId={props.projectId}
                    data={selectedDataType === 'job_description' ? jobDescriptionList[selectedIndex] : candidateList[selectedIndex]}
                    data_type={selectedDataType}
                    language={language}
                    minScoreExpected={minScoreExpected}
                    onClose={handleDialogClose}/>}

                {showQuery && <QueryData
                    projectId={props.projectId}
                    data_type={selectedDataType}
                    language={language}
                    minScoreExpected={minScoreExpected}
                    onClose={handleDialogClose}/>}

            </Box>
        </Box>
    )
}


//=================================================================================================================
// <JobDescriptionDetail/> COMPONENT
//=================================================================================================================
interface JobDescriptionDetailProps {
    mode: string;
    data: JobDescription;
    data_type: string;
    onSubmit: (data_type: string, jobdescription: JobDescription) => void;
    onClose: () => void;
}

function formatData(jsonString: string) {

    let plainText = "";
    try {
        const jsonObj = JSON.parse(jsonString);
        for (const [key, value] of Object.entries(jsonObj)) {

            console.log(key, value)

            // check if key contains title
            if (key.toLowerCase() === "title") {
                continue;
            }

            let valueAsString = JSON.stringify(value);
            valueAsString = valueAsString.replace(/"/g, "");

            let lines = valueAsString.split(/\\n|\. /g);
            let lineAsString = ""
            for (let line of lines) {
                lineAsString += line.trim() + "\n";
            }

            plainText += `<${key.toUpperCase()}>\n\n${lineAsString.trim()}\n\n`;
        }
    } catch (error) {
        console.log(`Fout bij het parsen van de JSON: ${error}`);
        return jsonString;
    }
    return plainText;

}

// ----------------------------------------------------------------------------------------------------------------
export function JobDescriptionDetail(props: JobDescriptionDetailProps) {
    const [open, setOpen] = useState(true);

    // Initialize state for each field using the external data
    const [uuid, setUuid] = useState(props.data.uuid);
    const [title, setTitle] = useState(props.data.title);
    const [original, setOriginal] = useState(props.data.original);
    const [processed, setProcessed] = useState(props.data.processed);
    const [mode, setMode] = useState(props.mode);
    const [datatype, setDatatype] = useState(props.data_type);
    const [switch_between_original_and_processed, setSwitch_between_original_and_processed] = useState(true);

    useEffect(() => {
        if (props.mode === 'edit') {
            setSwitch_between_original_and_processed(false);
        }
    }, [])


    function handleSubmit() {

        if (props.mode === 'view') {
            setOpen(false);
            props.onClose();
        } else if (mode === 'edit') {
            const updatedData: JobDescription = {
                uuid,
                title: title,
                original: "",
                processed: processed,
                status: ""
            };
            props.onSubmit(datatype, updatedData);
        } else if (mode === 'add') {
            const newData: JobDescription = {
                uuid: "",
                title: title,
                original: original,
                processed: "",
                status: ""
            };
            props.onSubmit(datatype, newData);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            // PaperComponent={DraggablePaper}
            PaperComponent={(paperProps) => <DraggablePaper {...paperProps} sx={{ minWidth: '300px', maxWidth: '500px' }} />}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} sx={{color: colorSchema.color3, cursor: 'move', textAlign: 'center'}}
                         id="draggable-dialog-title">
                {mode.charAt(0).toUpperCase() + mode.slice(1)} job description
            </DialogTitle>

            <DialogContentText>
            </DialogContentText>
            <DialogContent>
                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">
                    <TextField
                        fullWidth
                        label="title"
                        id="title"
                        value={title}
                        disabled={mode === 'view'}
                        onChange={(event) => setTitle(prevState => event.target.value)}
                    />

                    {mode !== 'add' && (
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px'}}>
                            <Button
                                variant="contained" color={"success"}
                                onClick={() => {
                                    setSwitch_between_original_and_processed(!switch_between_original_and_processed);
                                }}
                            >
                                {switch_between_original_and_processed ? 'Processed' : 'Original'}
                            </Button>
                        </Box>
                    )}

                    {switch_between_original_and_processed && (
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={25}
                            label="original"
                            id="original"
                            value={original}
                            disabled={mode === 'view' || props.mode === 'edit'}
                            onChange={(event) => setOriginal(prevState => event.target.value)}
                        />
                    )}

                    {(mode !== 'add' && !switch_between_original_and_processed) && (
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={25}
                            label="processed"
                            id="processed"
                            value={processed}
                            disabled={mode === 'view'}
                            onChange={(event) => {
                                setProcessed(prevState => event.target.value)
                            }}
                        />

                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                {props.mode === 'view' ? (
                    <Button autoFocus variant={"contained"} onClick={props.onClose}>
                        Return
                    </Button>
                ) : (
                    <>
                        <Button variant={"contained"}  onClick={handleSubmit}>Submit</Button>
                        <Button variant={"contained"} color={"warning"} onClick={props.onClose}>Cancel</Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}


//=================================================================================================================
// <CandidateDetail/> COMPONENT
//=================================================================================================================
interface CandidateDetailProps {
    mode: string;
    data: Candidate;
    data_type: string;
    onSubmit: (data_type: string, candidate: Candidate) => void;
    onClose: () => void;
}

// ----------------------------------------------------------------------------------------------------------------

export function CandidateDetail(props: CandidateDetailProps) {
    const [open, setOpen] = useState(true);

    // Initialize state for each field using the external data
    const [mode, setMode] = useState(props.mode);
    const [uuid, setUuid] = useState(props.data.uuid);
    const [title, setTitle] = useState(props.data.title);
    const [original, setOriginal] = useState(props.data.original);
    const [datatype, setDatatype] = useState(props.data_type);
    const [switch_between_original_and_processed, setSwitch_between_original_and_processed] = useState(true);
    const [processed, setProcessed] = useState(props.data.processed);

    function handleSubmit() {

        if (props.mode === 'view') {
            setOpen(false);
            props.onClose();
        } else if (props.mode === 'edit') {
            const updatedData: Candidate = {
                uuid: uuid,
                title: title,
                original: "",
                processed: processed,
                status: ""
            };
            props.onSubmit(datatype, updatedData);
        } else if (props.mode === 'add') {
            const newData: Candidate = {
                uuid: "",
                title: title,
                original: original,
                processed: "",
                status: ""
            };
            props.onSubmit(datatype, newData);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            // PaperComponent={DraggablePaper}
            PaperComponent={(paperProps) => <DraggablePaper {...paperProps} sx={{ minWidth: '300px', maxWidth: '500px' }} />}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} sx={{color: colorSchema.color3, cursor: 'move', textAlign: 'center'}}
                         id="draggable-dialog-title">
                {mode.charAt(0).toUpperCase() + mode.slice(1)} candidate
            </DialogTitle>

            <DialogContentText sx={{mx: 5, my: 3}}>
            </DialogContentText>
            <DialogContent>
                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">
                    <TextField
                        fullWidth
                        label="title"
                        id="title"
                        value={title}
                        disabled={props.mode === 'view'}
                        onChange={(event) => setTitle(event.target.value)}
                    />

                    {mode !== 'add' && (
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px'}}>
                            <Button
                                variant="contained" color={"success"}
                                onClick={() => {
                                    setSwitch_between_original_and_processed(!switch_between_original_and_processed);
                                }}
                            >
                                {switch_between_original_and_processed ? 'Processed' : 'Original'}
                            </Button>
                        </Box>
                    )}

                    {switch_between_original_and_processed && (
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={25}
                            label="original"
                            id="original"
                            value={original}
                            disabled={props.mode === 'view' || props.mode === 'edit'}
                            onChange={(event) => setOriginal(event.target.value)}
                        />)}


                    {!switch_between_original_and_processed && (
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={25}
                            label="processed"
                            id="processed"
                            value={processed}
                            disabled={props.mode === 'view'}
                            onChange={(event) => setProcessed(event.target.value)}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                {props.mode === 'view' ? (
                    <Button autoFocus variant={"contained"} onClick={props.onClose}>
                        Return
                    </Button>
                ) : (
                    <>
                        <Button variant={"contained"} autoFocus onClick={handleSubmit}>Submit</Button>
                        <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                            Cancel
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

//=================================================================================================================
// QUERY DATA COMPONENT
//=================================================================================================================
interface QueryDataProps {
    projectId: string;
    data_type: string;
    language: string;
    minScoreExpected: number;
    onClose: () => void;
}

function QueryData(props: QueryDataProps) {
    const [open, setOpen] = useState(true);
    const [dataType, setDataType] = useState(props.data_type);
    const [language, setLanguage] = useState(props.language);
    const [minScoreExpected, setMinScoreExpected] = useState(props.minScoreExpected);

    const [query, setQuery] = useState('');
    const [queryResultList, setQueryResultList] = useState<QueryResult[]>([]);
    const [queryResultListLength, setQueryResultListLength] = useState(-1);


    function handleSearchClick() {

        console.log(query)
        if (dataType === 'job_description') {
            QueryForJobDescriptions(props.projectId, query, minScoreExpected, 5).then((data: QueryResult[]) => {
                setQueryResultList(data);
                setQueryResultListLength(data.length);
            })

        } else if (dataType === 'candidate') {
            QueryForCandidates(props.projectId, query, minScoreExpected, 5).then((data: QueryResult[]) => {
                setQueryResultList(data);
                setQueryResultListLength(data.length);
            })
        }
    }

    function handleRequest(target: QueryResult, action: string) {
        console.log("Handle Request to be implemented")
    }


    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            // PaperComponent={DraggablePaper}
            PaperComponent={(paperProps) => <DraggablePaper {...paperProps} sx={{ minWidth: '300px', maxWidth: '500px' }} />}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} color={colorSchema.color3}
                         sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
                Searching for {props.data_type === 'job_description' ? 'job descriptions' : 'candidates'}
            </DialogTitle>

            <DialogContentText sx={{}}>
            </DialogContentText>
            <DialogContent>
                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">
                    <Typography variant={"h4"} color={colorSchema.color4}
                                sx={{marginBottom: "20px"}}>Query</Typography>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="name"
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        fullWidth
                    />
                </Box>
                {queryResultListLength === 0 && (
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        I was unable to find any matches
                    </Alert>
                )}
                {/* -----------------------------------------------------------------------------  */}
                <Box>
                    <Box sx={{borderBottom: "1px solid black", marginBottom: "20px", marginTop: "10px"}}>
                        <Typography
                            variant={"h5"}>{props.data_type === 'job_description' ? 'Potential job descriptions found' : 'Potential candidates found'}</Typography>
                    </Box>
                    <ShowQueryResults queryResultList={queryResultList} onAction={handleRequest}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} autoFocus onClick={handleSearchClick}>
                    Search
                </Button>
                <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                    Return
                </Button>

            </DialogActions>
        </Dialog>
    )


}

// ---------------------------------------------------------------------------------------------------------------
// SearchExtData
// ---------------------------------------------------------------------------------------------------------------
interface SearchMatchProps {
    projectId: string;
    data_type: string;
    data: Candidate | JobDescription;
    language: string;
    minScoreExpected: number;
    onClose: () => void;
}


function SearchMatchData(props: SearchMatchProps) {
    const [open, setOpen] = useState(true);
    const [data, setData] = useState(props.data);
    const [dataType, setDataType] = useState(props.data_type);
    const [language, setLanguage] = useState(props.language);
    const [minScoreExpected, setMinScoreExpected] = useState(props.minScoreExpected);

    const [queryResultList, setQueryResultList] = useState<QueryResult[]>([]);
    const [queryResultListLength, setQueryResultListLength] = useState(-1);
    const [showActionResult, setShowActionResult] = useState(false);
    const [actionResult, setActionResult] = useState<string[]>([]);
    const [actionResultComplete, setActionResultComplete] = useState(false);


    function handleSearchClick() {

        console.log(props);
        if (dataType === 'job_description') {
            findCandidates(props.projectId, data.uuid, minScoreExpected, 5, "").then((data: QueryResult[]) => {
                setQueryResultList(data);
                setQueryResultListLength(data.length);
            })

        } else if (dataType === 'candidate') {
            findJobs(props.projectId, data.uuid, minScoreExpected, 5, "").then((data: QueryResult[]) => {
                setQueryResultList(data);
                setQueryResultListLength(data.length);
            })
        }
    }

    // Bepaal hier wat er dient te gebeuren indien de gebruiker een bepaalde interactie vraag voor een match
    function handleRequest(target: QueryResult, action: string) {

        let currentCandidateId = "";
        let currentJobId = "";

        if (dataType === 'job_description') {
            currentCandidateId = target.uuid;
            currentJobId = data.uuid;
        }
        if (dataType === 'candidate') {
            currentJobId = target.uuid;
            currentCandidateId = data.uuid;
        }

        setActionResult([]);
        const prompt = "";
        const useOriginal = false;
        runGPTRequest(props.projectId, action, language, currentCandidateId, currentJobId, prompt, useOriginal, onDataReceived).then(() => {
            setActionResultComplete(true)

            // runActionRequest(props.projectId, action, language, currentCandidateId, currentJobId, onDataReceived).then(() => {
            //     setActionResultComplete(true)
        })

        setShowActionResult(true);
    }

    const onDataReceived = (chunk: string) => {
        const lines = chunk.split(/(?<=\. )/);
        const newActionResult = lines.map((line, index) => {
            console.log(line);
            return line;
        });

        setActionResult((prevResult) => [...prevResult, ...newActionResult]);
        setActionResultComplete(true);
    };

    useEffect(() => {
        console.log(actionResult);
    }, [actionResult]);


    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            // PaperComponent={DraggablePaper}
            PaperComponent={(paperProps) => <DraggablePaper {...paperProps} sx={{ minWidth: '300px', maxWidth: '500px' }} />}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} color={colorSchema.color3}
                         sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
                Searching for
                a {props.data_type === 'job_description' ? 'matching candidates' : 'mathing job descriptions'}
            </DialogTitle>

            <DialogContentText sx={{}}>
            </DialogContentText>
            <DialogContent>
                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">
                    {/*<Box sx={{borderBottom: "1px solid black"}}>*/}
                    {/*    <Typography*/}
                    {/*        variant={"h5"}>{props.data_type === 'job_description' ? 'The job description that has been chosen' : 'The candidate that has been chosen'}</Typography>*/}
                    {/*</Box>*/}
                    <Typography variant={"h4"} color={colorSchema.color4}
                                sx={{marginBottom: "20px"}}>{props.data.title}</Typography>
                </Box>
                {queryResultListLength === 0 && (
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        I was unable to find any matches
                    </Alert>
                )}
                {/* -----------------------------------------------------------------------------  */}
                <Box>
                    <Box sx={{borderBottom: "1px solid black", marginBottom: "20px"}}>
                        <Typography
                            variant={"h5"}>{props.data_type === 'job_description' ? 'Potential candidates found' : 'Potential job descriptions found'}</Typography>
                    </Box>
                    <ShowQueryResults queryResultList={queryResultList} onAction={handleRequest}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} autoFocus onClick={handleSearchClick}>
                    Search
                </Button>
                <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                    Return
                </Button>

            </DialogActions>
            {/* -----------------------------------------------------------------------------  */}
            {/* Show the chatGPT request feedback                                              */}
            {/* -----------------------------------------------------------------------------  */}
            {showActionResult && (
                <Dialog
                    open={showActionResult}
                    onClose={() => {
                        setShowActionResult(false)
                    }}
                    // PaperComponent={DraggablePaper}
                    PaperComponent={(paperProps) => <DraggablePaper {...paperProps} sx={{ minWidth: '300px', maxWidth: '500px' }} />}
                    sx={{
                        left: '100px',
                        top: '10px',
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle variant={"h4"} id="alert-dialog-title"
                                 sx={{
                                     borderBottom: "1px solid black",
                                     marginBottom: "20px",
                                     color: colorSchema.color3
                                 }}>
                        Motivation received
                    </DialogTitle>
                    <DialogContent>
                        {actionResult.map((line, index) => {
                            if (line.trim() === '') {
                                return <br key={index}/>;
                            }
                            return (
                                <Typography sx={{marginBottom: '2px', textAlign: 'justify'}} key={index}>
                                    {line}
                                </Typography>
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        {actionResultComplete && (
                            <Button color={"warning"} variant={"contained"} onClick={() => {
                                setShowActionResult(false)
                            }}>Return</Button>
                        )}
                    </DialogActions>
                </Dialog>
            )
            }

        </Dialog>
    )
}


//------------------------------------------------------------------------------------------------------------
// Show Query Results
// Show found candidates or job descriptions as accordion with extra options
//------------------------------------------------------------------------------------------------------------
interface ShowQueryResultsProps {
    queryResultList: QueryResult[];
    onAction: (data: QueryResult, instruction: string) => void;
}

function ShowQueryResults(props: ShowQueryResultsProps) {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <div>
            {props.queryResultList.map((result, index) => (
                <Accordion
                    key={index} expanded={expanded === 'r' + index} onChange={handleChange('r' + index)}>
                    <AccordionSummary
                        sx={{backgroundColor: colorSchema.secondary}}
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={"result_" + index}
                        id={"result_" + index}
                    >
                        <Typography variant={"h6"} textTransform={"uppercase"} sx={{flexGrow: 1, textAlign: "left"}}>
                            {result.title}
                        </Typography>
                        <Typography variant={"h6"} sx={{flexGrow: 0, textAlign: "right"}}>
                            Score: {result.score}%
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid sx={{
                            padding: '10px',
                            marginBottom: '5px',
                            color: "white",
                            backgroundColor: colorSchema.secondary,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <Grid sx={{flexGrow: 1, marginLeft: '20px', textAlign: "right"}}>
                                <Button size={"small"} color={"warning"} variant={"contained"} sx={{color: "white"}}
                                        onClick={() => {
                                            props.onAction(result, "why")
                                        }}>
                                    Motivate Match
                                </Button>
                                <Button size={"small"} color={"warning"} variant={"contained"}
                                        sx={{color: "white", marginLeft: '10px'}}
                                        onClick={() => {
                                            props.onAction(result, "invite")
                                        }}>
                                    Create Invitation Mail
                                </Button>
                            </Grid>
                        </Grid>

                        <Typography>
                            {result.data}
                        </Typography>

                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}

interface CandidateFormProps {
    onSubmit: (title: string, file: File | null) => void;
    onClose: () => void;
}

function CandidateForm(props: CandidateFormProps) {
    const [title, setTitle] = useState('');
    const [notes, setNotes] = useState("")
    const [file, setFile] = useState<File | null>(null);
    const [dragging, setDragging] = useState(false);
    const [open, setOpen] = useState(true);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            setFile(file);
            const filename = file.name.split(".")[0]; // Extract filename without extension
            const fullPath = event.target.value;
            setTitle(filename);
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
        const fileList = event.dataTransfer.files;
        if (fileList && fileList.length > 0) {
            setFile(fileList[0]);
        }
    };

    const handleSubmit = () => {
        props.onSubmit(title, file);
    };

    return (

        <Dialog
            open={open}
            onClose={props.onClose}
            // PaperComponent={DraggablePaper}

            PaperComponent={(paperProps) => <DraggablePaper {...paperProps} sx={{ minWidth: '300px', maxWidth: '500px' }} />}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} sx={{cursor: 'move', textAlign: 'center', color: colorSchema.color3}}
                         id="draggable-dialog-title">
                New Candidate Registration
            </DialogTitle>

            <DialogContentText sx={{mx: 5, my: 3}}>
            </DialogContentText>
            <DialogContent>

                <Grid container direction={"column"} spacing={1.5}>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Candidate name
                        </Typography>
                        <TextField
                            required
                            id="name"
                            name="name"
                            label="name"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Notes
                        </Typography>
                        <TextField
                            required
                            id="notes"
                            name="notes"
                            label="notes"
                            value={notes}
                            multiline={true}
                            rows={10}
                            onChange={(event) => setNotes(event.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <div
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            style={{
                                border: `2px dashed ${
                                    dragging ? '#00bcd4' : 'rgba(0, 0, 0, 0.23)'
                                }`,
                                padding: '1rem',
                                marginTop: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <input
                                required
                                id="file"
                                name="file"
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                            />
                            <label htmlFor="file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    style={{marginRight: '1rem'}}
                                >
                                    Select file
                                </Button>
                                {file ? file.name : 'Drag and drop file here'}
                            </label>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} autoFocus onClick={handleSubmit}>
                    Submit
                </Button>
                <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                    Cancel
                </Button>

            </DialogActions>

        </Dialog>
    );
}







