import React, {useEffect, useState} from 'react';
import {Container, Typography} from '@mui/material';
import {useLocation, useSearchParams} from 'react-router-dom';
import ChatBoostrMenu from '../components/chatboostr/ChatBoostrMenu';
import {colorSchema} from "../data/theme";

interface CustomerDataPageProps {
    message: string;
}

const ChatBoostrPage = (props: CustomerDataPageProps) => {
    const [projectName, setProjectName] = useState<string>('');
    const [projectUUID, setProjectUUID] = useState<string>('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchParams] = useSearchParams();


    // Load URL parameter and initiate page view.
    useEffect(() => {
        console.log('Loading ChatBoostrPage');

        const params: any = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
            console.log(`[${key}] = ${value}`);
        });

        setProjectUUID(params['uuid']);
        setProjectName(params['project_name']);
        setIsLoaded(true);
    }, [searchParams]);


    // View
    return (
        <Container maxWidth="xl" sx={{marginTop: '30px'}}>
            <Typography variant={"h3"} sx={{
                color: colorSchema.primary,
                textAlign: "center",
                marginTop: -2,
                marginBottom: -2
            }}>Templates</Typography>
            {isLoaded && (
                <ChatBoostrMenu projectId={projectUUID}/>
            )}
        </Container>
    );
};

export default ChatBoostrPage;

