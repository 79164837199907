import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, ButtonGroup, CircularProgress, Grid, Tab, Tabs, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DraggablePaper} from "../general/Styling.snippets";
import {colorSchema} from "../../data/theme";
import {a11yProps, TabPanel} from "../general/TabelPanel.snippets";
import ActionBox from "./ActionBox";
import {Item} from "./ChatBoostrMenu";

import {ControlledEditor} from "../editor/EditorComponent";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {ContentState, EditorState, convertToRaw} from "draft-js";
import ImageUpload from "../ui/UploadImage";
import {debugMode} from "../managers/generalManager";

export interface ManageItemProps {
    mode: string;
    isProcessing: boolean;
    item: Item;
    message: string;
    onSubmit: (mode: string, item: Item) => void;
    onCancel: () => void;
}

export default function ManageItem(props: ManageItemProps) {

    const [mode, ] = useState(props.mode);
    const [item, setItem] = useState(props.item);

    const [title, setTitle] = useState(props.item.title);
    const [description, setDescription] = useState(props.item.description);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [sequence, setSequence] = useState(props.item.sequence);
    const [actionAfter, setActionAfter] = useState(props.item.actionEnd);
    const [actionBefore, setActionBefore] = useState(props.item.actionStart);
    const [selectedActionBefore, setSelectedActionBefore] = useState(props.item.actionStartType);
    const [selectedActionAfter, setSelectedActionAfter] = useState(props.item.actionEndType);
    const [type, setType] = useState(props.item.type);

    const [tabValue, setTabValue] = React.useState(0);
    const [redrawCounter, setRedrawCounter] = useState(0);

    useEffect(() => {
        setTitle(props.item.title);
        setDescription(props.item.description)

        let {contentBlocks, entityMap} = htmlToDraft(props.item.description);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const _editorState = EditorState.createWithContent(contentState);
        setEditorState(_editorState);

        setSequence(props.item.sequence);
        setActionAfter(props.item.actionEnd);
        setActionBefore(props.item.actionStart);
        setSelectedActionBefore(props.item.actionStartType);
        setSelectedActionAfter(props.item.actionEndType);
        setType(props.item.type);
        setRedrawCounter(redrawCounter + 1);

        setItem(props.item);
    }, [props.item]);

    // useEffect(() => {
    //     let {contentBlocks, entityMap} = htmlToDraft(description);
    //
    //     const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    //     const content = draftToHtml(convertToRaw(contentState));
    //     const _editorState = EditorState.createWithContent(contentState);
    //
    //     setEditorState(_editorState);
    // }, []);

    function handleDetach() {
        props.onSubmit('detach', item);
    }

    function handleDelete() {
        props.onSubmit('delete', item);
    }

    function handleSave() {
        debugMode && console.log("handleSave", item);
        updateItem();
        props.onSubmit('save', item);
    }

    function updateItem() {
        item.title = title;

        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        debugMode && console.log("description", content)
        item.description = content;

        item.sequence = sequence;
        item.actionEnd = actionAfter;
        item.actionStart = actionBefore;
        item.actionStartType = selectedActionBefore;
        item.actionEndType = selectedActionAfter;
        item.type = type;

        debugMode && console.log("updateItem", item)
    }

    // EDIT SERVICE MODE : SWITCH BETWEEN ACTION BEFORE/AFTER
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleEditorStateChange(newState: EditorState) {
        setEditorState(prevState => newState);
    }

    return (
        <Grid item xs={8} paddingLeft={2}>

            {/* Dialog buttons */}
            <Grid container>
                <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography variant={"h5"}
                                color={colorSchema.color1} fontWeight={500} fontStyle={"italic"}>
                        {type == 'service' ? "Service Details" : "Topic Details"}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    <ButtonGroup>
                        {type !== 'menu' && (
                            <Button variant={"contained"} color={"warning"}
                                    sx={{backgroundColor: colorSchema.color2}}
                                    onClick={handleDetach}>Detach
                                Service</Button>
                        )}
                        <Button variant={"contained"} color={"warning"}
                                sx={{backgroundColor: colorSchema.color2}}
                                onClick={handleDelete}>Delete</Button>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{backgroundColor: colorSchema.color1}}
                            onClick={handleSave}
                        >
                            {!props.isProcessing ? (
                                "Save changes"
                            ) : (
                                <React.Fragment>
                                    <Typography variant={"body2"}
                                                color={"white"}>Saving... </Typography>
                                    <CircularProgress color="inherit" size="sm"/>
                                </React.Fragment>
                            )}
                        </Button>


                    </ButtonGroup>
                </Grid>
            </Grid>

            {/* Dialog title, description and sequence */}
            <Grid>
                <Typography variant={"h6"} paddingLeft={1}
                            color={colorSchema.color2}>Title</Typography>
                <TextField value={title}
                           fullWidth
                           onChange={(event) => {
                               const selectedValue = event.target.value;
                               setTitle(selectedValue);
                           }}
                />

                <Typography variant={"h6"} paddingLeft={1} color={colorSchema.color2}
                            marginTop={2}>Description</Typography>
                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    multiline*/}
                {/*    rows={10}*/}
                {/*    value={description}*/}
                {/*    onChange={(event) => {*/}
                {/*        const selectedValue = event.target.value;*/}
                {/*        setDescription(selectedValue);*/}
                {/*    }}/>*/}

                <Box height="400px" borderRadius={"4px"} padding={"14px"}  overflow="auto" border="1px solid #C5C5C5">
                        <ControlledEditor content={description} onEditorStateChange={handleEditorStateChange}/>
                </Box>

            </Grid>

            <Grid>
                <ImageUpload/>
            </Grid>


            <Grid>
                <Typography variant={"h6"} paddingLeft={1} color={colorSchema.color2}
                            marginTop={2}>Sequence</Typography>
                <TextField
                    value={sequence}
                    onChange={(event) => {
                        const selectedValue = parseInt(event.target.value, 10) | 0;
                        // Check if the input is a valid number and within the range of 0 to 100
                        if (!isNaN(selectedValue) && selectedValue >= 0 && selectedValue <= 100) {
                            setSequence(selectedValue);
                        }
                    }}
                />

            </Grid>

            {/* Dialog action fields for services */}
            {(type === 'service' && redrawCounter) && (
                <>
                    <Typography variant={"h6"} color={colorSchema.color2} paddingLeft={1}
                                marginTop={2}>Action</Typography>

                    <Grid item xs={12}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={tabValue} onChange={handleTabChange}
                                  aria-label="basic tabs example">
                                <Tab label="Before" {...a11yProps(0)} />
                                <Tab label="After" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0}>
                            <ActionBox action={actionBefore} selectedAction={selectedActionBefore}
                                       mode={mode}
                                       redrawTrigger={redrawCounter}
                                       onSelectedActionUpdate={(selected) => setSelectedActionBefore(selected)}
                                       onActionUpdate={(action) => {
                                           setActionBefore(action)
                                       }}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <ActionBox action={actionAfter} selectedAction={selectedActionAfter}
                                       mode={mode}
                                       redrawTrigger={redrawCounter}
                                       onSelectedActionUpdate={(selected) => setSelectedActionAfter(selected)}
                                       onActionUpdate={(action) => {
                                           setActionAfter(action)
                                       }}/>
                        </TabPanel>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

interface CreateItemProps {
    open: boolean;
    type: 'service' | 'topic' | 'menu' | '';
    message: string;
    onSubmit: (item: Item) => void;
    onCancel: () => void;
}

// ================================================================================================================ //
//                                                  CREATE ITEM                                                     //
// ================================================================================================================ //
export function CreateItem(props: CreateItemProps) {

    const [open, setOpen] = useState(props.open);
    const [item, setItem] = useState<Item>({
        id: '',
        title: '',
        description: '',
        sequence: 0,
        actionStart: '',
        actionEnd: '',
        actionStartType: '',
        actionEndType: '',
        language: 'en',
        type: props.type
    });

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [sequence, setSequence] = useState("");
    const [actionAfter, setActionAfter] = useState("");
    const [actionBefore, setActionBefore] = useState("");
    const [selectedActionBefore, setSelectedActionBefore] = useState("");
    const [selectedActionAfter, setSelectedActionAfter] = useState("");
    const [type, setType] = useState(props.type);

    const [message, setMessage] = useState(props.message);
    const [isValid, setIsValid] = useState(false);

    function handleSubmitClicked() {
        setOpen(false);

        // update item with new values
        item.type = type;
        item.title = title;
        item.description = description;

        // submit request to parent
        setOpen(false);
        props.onSubmit(item);
    }

    // Title and Descriptions are required fields
    useEffect(() => {
        if (title.trim().length > 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [description, title]);

    function handleCancelClicked() {
        setOpen(false);
        props.onCancel();
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleCancelClicked()}
            PaperComponent={DraggablePaper}
            aria-labelledby="ct-dialog"
            aria-describedby="ct-dialog"
        >
            <DialogTitle id="item-title" variant={"h3"} color={colorSchema.color2} fontWeight={500} align={"center"}
                         sx={{textTransform: "capitalize"}}>
                Create {type}
            </DialogTitle>
            <DialogContent>
                <Grid>
                    <Typography variant={"h6"} color={colorSchema.color2} paddingLeft={1}>Title</Typography>
                    <TextField
                        fullWidth
                        value={title}
                        onChange={(event) => {
                            setTitle(event.target.value);
                        }}
                    />

                    <Typography variant={"h6"} color={colorSchema.color2} paddingLeft={1}
                                marginTop={2}>Description</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={15}
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}/>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"success"} variant={"contained"} disabled={!isValid}
                        onClick={() => handleSubmitClicked()}
                        autoFocus>Confirm</Button>
                <Button color={"warning"} variant={"contained"}
                        onClick={() => handleCancelClicked()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}