import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Grid, TablePagination} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import {colorSchema} from "../../data/theme";


interface SimpleListProps {
    projectId: string;
    data: ListData[];
    data_type: string;
    has_search: boolean;
    onRequest: (mode: string, data_type: string, uuid?: string, index?: number) => void;
}

interface ListData {
    title: string;
    uuid: string;
    status: string;
}

// SIMPLE LIST COMPONENT
// ----------------------------------------------------------------------------------------------------------------
function SimpleList(props: SimpleListProps) {

    const [dataList, setDataList] = useState<ListData[]>(props.data);
    const rows_per_page = 10;
    const [page, setPage] = useState(0);

    const customColumnStyle = {maxWidth: "10px", backgroundColor: "green"};

    useEffect(() => {
    }, [])

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    // JSX.element
    return (
        <Grid container marginTop={2}>
            <Grid item xs={12} marginBottom={1}>
                <ButtonGroup sx={{marginBottom: '4px'}} size="small" variant="contained"
                             aria-label="outlined primary button group">
                    <Button onClick={() => props.onRequest('add', props.data_type)}>
                        Add
                    </Button>
                    <Button onClick={() => props.onRequest('refresh', props.data_type)}>
                        Refresh
                    </Button>
                    <Button onClick={() => props.onRequest('query', props.data_type)}>
                        Query
                    </Button>
                </ButtonGroup>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="SimpleList">
                        <TableHead>
                            <TableRow sx={{backgroundColor: colorSchema.color4, color: 'white'}}>
                                <TableCell sx={{
                                    color: 'inherit',
                                    fontWeight: 'bold',
                                    letterSpacing: '0.5px'
                                }}>Title</TableCell>
                                <TableCell
                                    sx={{color: 'inherit', width: '100px', fontWeight: 'bold', letterSpacing: '0.5px'}}
                                    align="right">Status</TableCell>
                                <TableCell
                                    sx={{color: 'inherit', width: '150px', fontWeight: 'bold', letterSpacing: '0.5px'}}
                                    align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataList
                                .slice(page * rows_per_page, page * rows_per_page + rows_per_page).map((record, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="td" scope="row" onClick={() => {
                                            props.onRequest('view', props.data_type, dataList[index + page * rows_per_page].uuid, index + page * rows_per_page)
                                        }}>
                                            {record.title}
                                        </TableCell>
                                        <TableCell component="td" align="right">
                                            {record.status}
                                        </TableCell>
                                        <TableCell component="td" align="right">
                                            <EditIcon onClick={() => {
                                                props.onRequest('edit', props.data_type, dataList[index + page * rows_per_page].uuid, index + page * rows_per_page)
                                            }}/>
                                            <ContentPasteSearchIcon sx={{color: 'green'}} onClick={() => {
                                                props.onRequest('search', props.data_type, dataList[index + page * rows_per_page].uuid, index + page * rows_per_page)
                                            }}/>
                                            <DeleteIcon sx={{color: 'red'}}
                                                        onClick={() => {
                                                            props.onRequest('delete', props.data_type, dataList[index + page * rows_per_page].uuid, index + page * rows_per_page)
                                                        }}/>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={dataList.length}
                    rowsPerPage={rows_per_page}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </Grid>
        </Grid>
    );
}

export default SimpleList;
