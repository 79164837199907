import React, {useEffect, useRef} from "react";

const SimpleObjects: React.FC = () => {
  const circle1 = useRef<HTMLDivElement>(null);
  const circle2 = useRef<HTMLDivElement>(null);
  const line = useRef<SVGLineElement>(null);

  useEffect(() => {
    const updateLine = () => {
      if (circle1.current && circle2.current && line.current) {
        const rect1 = circle1.current.getBoundingClientRect();
        const rect2 = circle2.current.getBoundingClientRect();

        line.current.setAttribute("x1", `${rect1.left + rect1.width / 2}`);
        line.current.setAttribute("y1", `${rect1.top + rect1.height / 2}`);
        line.current.setAttribute("x2", `${rect2.left + rect2.width / 2}`);
        line.current.setAttribute("y2", `${rect2.top + rect2.height / 2}`);
      }
    };

    updateLine();
    window.addEventListener("mousemove", updateLine);

    return () => {
      window.removeEventListener("mousemove", updateLine);
    };
  }, []);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    const x = e.clientX;
    const y = e.clientY;

    if (e.currentTarget) {
      e.currentTarget.style.left = `${x}px`;
      e.currentTarget.style.top = `${y}px`;
    }
  };

  return (
    <div className="demo">
      <svg className="line-container">
        <line ref={line} stroke="black" strokeWidth="5" />
      </svg>
      <div
        ref={circle1}
        className="circle"
        draggable
        onDrag={handleDrag}
      ></div>
      <div
        ref={circle2}
        className="circle"
        draggable
        onDrag={handleDrag}
      ></div>
    </div>
  );
};

export default SimpleObjects;
