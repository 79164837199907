import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, TablePagination, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DraggablePaper} from "../general/Styling.snippets";
import {colorSchema} from "../../data/theme";
import {CbService, Item} from "../../backend/chatboostr.services";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


interface ServiceListProps {
    open: boolean;
    mode: string;
    serviceList: CbService[];
    onRequest: (mode: string, serviceId: string, index: number) => void;
    onCancel: () => void;
}

export default function ServiceList(props: ServiceListProps) {
    const [open, setOpen] = useState(props.open);
    const [serviceList, setServiceList] = useState<CbService[]>(props.serviceList);
    const [mode, setMode] = useState(props.mode);

    const rows_per_page = 10;
    const [page, setPage] = useState(0);

    useEffect(() => {
        setServiceList(props.serviceList);
    }, [props.serviceList]);

    function handleCancelClicked() {
        setOpen(false);
        props.onCancel();
    }

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleCancelClicked()}
            PaperComponent={DraggablePaper}
            aria-labelledby="ct-dialog"
            aria-describedby="ct-dialog"
        >
            <DialogTitle id="item-title" variant={"h5"} color={colorSchema.color1} fontWeight={500} align={"center"}
                         sx={{textTransform: "capitalize"}}>
                {props.mode} Service
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{}} aria-label="SimpleList">
                            <TableHead>
                                <TableRow sx={{backgroundColor: colorSchema.color4, color: 'white'}}>
                                    <TableCell sx={{
                                        color: 'inherit',
                                        /* fontWeight: 'bold', */
                                        letterSpacing: '0.5px'
                                    }}>Title</TableCell>
                                    <TableCell
                                        sx={{
                                            color: 'inherit',
                                            /* fontWeight: 'bold', */
                                            letterSpacing: '0.5px'
                                        }}
                                        align="left">Description</TableCell>

                                    {mode === 'edit' && (
                                        <TableCell
                                            sx={{
                                                color: 'inherit',
                                                width: '150px',
                                                /* fontWeight: 'bold', */
                                                letterSpacing: '0.5px'
                                            }}
                                            align="right">Actions</TableCell>
                                    )}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {serviceList
                                    .slice(page * rows_per_page, page * rows_per_page + rows_per_page).map((record, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="td" scope="row" onClick={() => {
                                                setOpen(false);
                                                props.onRequest('select', serviceList[index + page * rows_per_page].uuid, index + page * rows_per_page);
                                            }}>
                                                {record.title}
                                            </TableCell>
                                            <TableCell component="td" align="left">
                                                {record.description}
                                            </TableCell>

                                            {mode === 'edit' && (
                                                <TableCell component="td" align="right">
                                                    <EditIcon onClick={() => {
                                                        setOpen(false);
                                                        props.onRequest('edit', serviceList[index + page * rows_per_page].uuid, index + page * rows_per_page)
                                                    }}/>
                                                    <DeleteIcon sx={{color: 'red'}}
                                                                onClick={() => {
                                                                    setOpen(false);
                                                                    props.onRequest('delete', serviceList[index + page * rows_per_page].uuid, index + page * rows_per_page)
                                                                }}/>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={serviceList.length}
                        rowsPerPage={rows_per_page}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"warning"} variant={"contained"} onClick={() => handleCancelClicked()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}