import {useState} from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box } from '@mui/material';

interface ZoomableImageProps {
    image: Uint8Array | undefined;
}

export function ZoomableImage( props: ZoomableImageProps ) {

    const [isZoomed, setIsZoomed] = useState(false);

    const handleZoomClick = () => {
        setIsZoomed(!isZoomed);
    };

    return (
        <>
            {props.image && (
                <Box style={{position: 'relative'}}
                >
                    <img
                        src={`data:image/png;base64,${props.image}`}
                        style={{
                            border: '1px solid gray',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            padding: '5px',
                            borderRadius: '4px',
                            maxWidth: isZoomed ? 'none' : '100%',
                            width: isZoomed ? '150%' : 'auto',
                            height: 'auto',
                            display: 'inline-block',
                            cursor: 'zoom-in',
                        }}
                        alt="Your Image"
                        onClick={handleZoomClick}
                    />
                    {isZoomed && (
                        <Box
                            style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                                backgroundColor: 'white',
                                padding: '5px',
                                borderRadius: '50%',
                                cursor: 'zoom-out',
                            }}
                            onClick={handleZoomClick}
                        >
                            <ZoomInIcon/>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
}
