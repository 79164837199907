import React from "react";
import { useRouteError } from "react-router-dom";
import SimpleObjects from "../components/ui/simpleObjects";

export default function UIDemoPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <SimpleObjects/>
  );
}