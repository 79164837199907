import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    ExtDataRecord,
    ExtDataRecordOut,
    getExtDataRecordList,
    QueryResult, runGPTRequest,
    runQuery,
    getSiteList, AddUpdateParameter, getParameter,
} from "../../backend/extdata.services";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    TablePagination,
    ButtonGroup, List, ListItem, ListItemButton, Checkbox, Alert
} from "@mui/material";
import Box from "@mui/material/Box";
import {DraggablePaper} from "../general/Styling.snippets";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {colorSchema} from "../../data/theme";
import {SelectWithData} from "../general/Site.snippets";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExtDataPageViewer from "./HandlePageInteraction";
import SynonymList, {SynonymRecord} from "./SynonymList";

// ---------------------------------------------------------------------------------------------------------------
// ExtDataRecordList
// ---------------------------------------------------------------------------------------------------------------
export interface ListExtDataRecordsProps {
    projectId: string;
    namespace: string;
    onRecordAdded: (data: ExtDataRecordOut, file: File | null | undefined, addToIndex: boolean) => void;
    onRecordDeleted: (record_uuid: string, namespace: string) => void;
    onRecordUpdate: (data: ExtDataRecordOut, file: File | null | undefined) => void;
    onUrlImport: (namespace: string, urls: string[], addToIndex: boolean) => void;
}

// ---------------------------------------------------------------------------------------------------------------
export function ExtDataRecordList(props: ListExtDataRecordsProps) {
    const [recordList, setRecordList] = useState<ExtDataRecord[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showSiteDialog, setShowSiteDialog] = useState(false);
    const [showPagesDialog, setShowPagesDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [searchDialog, setSearchDialog] = useState(false);
    const [selectedRecordIndex, setSelectedRecordIndex] = useState(-1); // Initialize with -1
    const [namespace, setNamespace] = useState<string>(props.namespace || namespaces[0].value);
    const [isAscending, setIsAscending] = useState(true);
    const [showSynonyms, setShowSynonyms] = useState(false);
    const [synonyms, setSynonyms] = useState<SynonymRecord[]>([]);

    const [mode, setMode] = useState("view");

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    // Handle pagination
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleRowsPerPage = (event: any, rows: React.SetStateAction<number>) => {
        setRowsPerPage(rows);
    };

    function sortRecordList(selectedField: keyof ExtDataRecord): void {
        setRecordList((prevList) => {
            const newList = [...prevList];

            newList.sort((a, b) => {
                // Ensure the values are strings and convert them to the same case
                const valueA = String(a[selectedField]).toLowerCase();
                const valueB = String(b[selectedField]).toLowerCase();

                if (isAscending) {
                    return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
                } else {
                    return valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
                }
            });

            setIsAscending(!isAscending);
            return newList;
        });
    }

    // Perform actions on the selected record (view, edit or delete).
    function handleRecordClick(index: number, selected_mode: string) {

        if (selected_mode === "pages") {
            console.log(recordList[index]);
            const filename = recordList[index].meta_data.resource;
            if (filename.endsWith("pdf")) {
                setSelectedFile(filename);
                setShowPagesDialog(true);
            }
        } else {
            setMode(selected_mode);
            setSelectedRecordIndex(index); // Store the selected index
            setShowDialog(true);
        }
    }

    // ------------- BUTTON HANDLERS -----------------
    // Trigger load external site dialog
    function handleAddSiteClick() {
        setShowSiteDialog(true);
    }

    function handleAddUpdate(data: ExtDataRecordOut, file: File | null | undefined, addToIndex: boolean) {
        setShowDialog(false);
        if (mode === 'add') {
            props.onRecordAdded(data, file, addToIndex);
        } else {
            props.onRecordUpdate(data, file);
        }
    }

    // Trigger search dialog
    function handleSearchClick() {
        setSearchDialog(true);
    }

    function handleSynonymsClick() {
        console.log("Synonyms clicked");
        setShowSynonyms(true);
    }

    function handleSynomynsSave(records: SynonymRecord[]) {
        let items_json = JSON.stringify(records);
        AddUpdateParameter(props.projectId,namespace, "synonyms", items_json).then(() => {
            console.log("Synonyms saved", records);
            setShowSynonyms(false);
        });
    }

    // ------------- IMPORT DATA -----------------
    // Load external data from selected project
    useEffect(() => {
        getExtDataRecordList(props.projectId, namespace).then(data => setRecordList(data));
        getParameter(props.projectId, namespace, "synonyms").then((synonyms_json: string) => {
            let synonyms: SynonymRecord[] = [];
            if (synonyms_json.startsWith("[{") && synonyms_json.endsWith("}]")) {
                try {
                    synonyms = JSON.parse(synonyms_json);
                    setSynonyms(synonyms);
                } catch (e) {
                    console.error("Error parsing synonyms JSON:", e);
                }
            }
            setSynonyms(synonyms);
        });

    }, [namespace])


    // ------------- DIALOG HANDLERS -----------------
    function handleDialogClose() {
        setSearchDialog(false);
        setShowDialog(false);
        setSelectedRecordIndex(-1); // Reset the selected index
    }

    // Import selected URLs
    const importSelectedUrls = (namespace: string, urls: string[], add_to_index: boolean) => {
        setShowSiteDialog(false);
        props.onUrlImport(namespace, urls, add_to_index);
    }

    const handleNamespaceSelected = (selection: Option) => {
        console.log(selection);
        setNamespace(selection.value);
    }

    // JSX.element
    return (
        <Grid container sx={{margin: '2px'}}>
            <Grid item xs={3}>
                <SelectWithData data={namespaces} id="SelectCategory" onSelect={handleNamespaceSelected}
                                selected={namespaces.findIndex((option) => option.value.toLowerCase() === namespace.toLowerCase())}
                                label={"Category"} isOpen={false} sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    m: 1,
                }}/>
            </Grid>
            <Grid item xs={9} sx={{textAlign: "end", alignSelf: "center"}}>
                <ButtonGroup sx={{marginBottom: '0px'}} size="small" variant="contained">
                    <Button onClick={() => handleRecordClick(-1, 'add')}>
                        Add
                    </Button>
                    <Button onClick={handleAddSiteClick}>
                        Add Site
                    </Button>
                    <Button onClick={() => handleSearchClick()}>
                        Search
                    </Button>
                    <Button onClick={() => handleSynonymsClick()}>
                        Synonyms
                    </Button>
                </ButtonGroup>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="ExtDataRecordList">
                        <TableHead>
                            <TableRow sx={{backgroundColor: colorSchema.color4, color: 'white'}}>
                                <TableCell sx={{color: 'inherit'}}
                                           onClick={() => sortRecordList('title')}>Title</TableCell>
                                <TableCell sx={{color: 'inherit'}}
                                           onClick={() => sortRecordList('namespace')}>Category</TableCell>
                                <TableCell sx={{color: 'inherit'}} align="right">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recordList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
                                <TableRow key={index} sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5' // Replace with your desired hover color
                                    }
                                }}>
                                    <TableCell component="th" scope="row"
                                               onClick={() => handleRecordClick(index + (page * rowsPerPage), "view")}

                                    >
                                        {record.title}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {record.namespace}
                                    </TableCell>
                                    <TableCell align="right">
                                        <EditIcon
                                            onClick={() => handleRecordClick(index + (page * rowsPerPage), "edit")}/>
                                        <FindInPageIcon sx={{color: 'green'}}
                                                        onClick={() => handleRecordClick(index + (page * rowsPerPage), "pages")}/>
                                        <DeleteIcon sx={{color: 'red'}}
                                                    onClick={() => props.onRecordDeleted(record.uuid, namespace)}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={recordList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={event => {
                        setRowsPerPage(parseInt(event.target.value, 10))
                        setPage(0)
                    }}
                />

                {showSynonyms && (
                    <SynonymList open={showSynonyms}
                                 synonyms={synonyms}
                                 onSave={handleSynomynsSave}
                                 onCancel={() => {
                                     setShowSynonyms(false)
                                 }}
                    />
                )}

                {showDialog && (
                    <ExtDataRecordDetail
                        data={selectedRecordIndex >= 0 ? recordList[selectedRecordIndex] : emptyRecord}
                        namespace={namespace}
                        onSubmit={handleAddUpdate}
                        mode={mode}
                        onClose={handleDialogClose}
                    />
                )}

                {showPagesDialog && (
                    <ExtDataPageViewer projectId={props.projectId}
                                       filename={selectedFile} onClose={() => setShowPagesDialog(false)}/>
                )}

                {showSiteDialog && (
                    <SiteList projectId={props.projectId} open={showSiteDialog} onClose={() => {
                        setShowSiteDialog(false)
                    }} onImport={importSelectedUrls}/>
                )}

                {searchDialog && <SearchExtData projectId={props.projectId} onClose={handleDialogClose}/>}
            </Grid>
        </Grid>

    )
}

// ---------------------------------------------------------------------------------------------------------------
// ExtDataRecordDetail
// ---------------------------------------------------------------------------------------------------------------
interface ExtDataRecordDetailProps {
    mode: string;
    namespace: string;
    data: ExtDataRecord;
    onSubmit: (updatedData: ExtDataRecordOut, file: File | null | undefined, addToIndex: boolean) => void;
    onClose: () => void;
}


const emptyRecord: ExtDataRecord = {
    uuid: '',
    title: '',
    original: '',
    processed: '',
    namespace: '',
    project: '',
    meta_data: {
        resource: '',
        type: '',
        language: '',
        extra: '',
    },
    chunks_original: 0,
    chunks_processed: 0,
};

export interface Option {
    value: string;
    label: string;
}

const truefalse: Option[] = [
    {value: 'false', label: 'False'},
    {value: 'true', label: 'True'},
];

const namespaces: Option[] = [
    {value: 'Corporate', label: 'Corporate'},
    {value: 'Helpdesk', label: 'Helpdesk'},
    {value: 'Service Delivery', label: 'Service Delivery'},
    {value: 'Legal', label: 'Legal'},
    {value: 'Blog', label: 'Blog&News'},
    {value: 'HR', label: 'Human Resources'},
    {value: 'Sales', label: 'Sales'},
    {value: 'Training', label: 'Training'},
    {value: 'Facts', label: 'Figures'},
    {value: 'Templates', label: 'Templates'},
    {value: 'Demo', label: 'Demo'},
];

export const languageOptions: Option[] = [
    {value: "nl", label: "Dutch"},
    {value: "en", label: "English"},
    {value: "fr", label: "French"},
    {value: "de", label: "Deutsch"},
    {value: "ar", label: "Arabic"},
];

export function isValidUrl(url: string): boolean {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
}

// ----------------------------------------------------------------------------------------------------------------

export function ExtDataRecordDetail(props: ExtDataRecordDetailProps) {
    const [open, setOpen] = useState(true);

    // Initialize state for each field using the external data
    const [uuid, setUuid] = useState(props.data.uuid);
    const [title, setTitle] = useState(props.data.title);
    const [original, setOriginal] = useState(props.data.original);
    const [processed, setProcessed] = useState(props.data.processed);

    const [metadataResource, setMetadataResource] = useState(props.data.meta_data.resource);
    const [metadataType, setMetadataType] = useState(props.data.meta_data.type);
    const [metadataExtra, setMetadataExtra] = useState(props.data.meta_data.extra);
    const [metadataLanguage, setMetadataLanguage] = useState<string>(props.data.meta_data.language ||
        localStorage.getItem('settings_language') || languageOptions[0].value)
    const [namespace, setNamespace] = useState<string>(props.namespace || namespaces[0].value)
    const [switch_between_original_and_processed, setSwitch_between_original_and_processed] = useState(true);
    const [file, setFile] = useState<File | null>(null);
    const [dragging, setDragging] = useState(false);
    const [isValidForSubmit, setIsValidForSubmit] = useState(false);
    const [addToIndex, setAddToIndex] = useState<boolean>(false);

    useEffect(() => {
        console.log("ExtDataRecordDetail: useEffect: props.data: ", props.data);
        console.log("ExtDataRecordDetail: useEffect: props.data.metadata_language: ", props.data.meta_data.language);
    }, []);

    function handleSubmit() {

        if (props.mode === 'view') {
            setOpen(false);
            props.onClose();
        } else {

            // Create a new ExtDataRecord object with the updated values
            const updatedData: ExtDataRecordOut = {
                uuid,
                title: title,
                original: original,
                processed: processed,
                namespace: namespace,
                project: props.data.project,
                keywords: "",
                metadata_resource: metadataResource,
                metadata_language: metadataLanguage,
                metadata_type: metadataType,
                metadata_extra: metadataExtra,

            };
            props.onSubmit(updatedData, file !== null ? file : undefined, addToIndex);
        }
    }

    useEffect(() => {

        if (title.trim().length > 0 &&
            (file !== null || original.trim().length > 0 || isValidUrl(metadataResource.trim())) &&
            metadataResource.trim().length > 0
        ) {
            setIsValidForSubmit(true);
        } else {
            setIsValidForSubmit(false);
        }


    }, [title, original, processed, metadataResource, metadataType, metadataExtra, namespace, file]);


    const handleLanguageSelected = (selected: Option) => {
        console.log(selected);
        setMetadataLanguage(selected.value);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;

        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            const filename = file.name.split(".")[0]; // Extract filename without extension
            const fullPath = event.target.value;
            setFile(file);

            console.log("Filename without extension:", filename);
            console.log("Full path:", fullPath);
            setTitle(filename);
            setMetadataResource(file.name);
        }
    };


    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
        const fileList = event.dataTransfer.files;
        if (fileList && fileList.length > 0) {
            setFile(fileList[0]);
        }
    };

    const handleNamespaceSelected = (selection: Option) => {
        console.log(selection);
        setNamespace(selection.value);
    }

    const handleAddToIndex = (selection: Option) => {
        setAddToIndex(prevState => selection.value === 'true');
    }


    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
                <Typography variant="h4" color={colorSchema.color2}>
                    {props.mode.charAt(0).toUpperCase() + props.mode.slice(1)} - External Data Record
                </Typography>
            </DialogTitle>

            <DialogContentText sx={{mx: 5, my: 3}}>
            </DialogContentText>
            <DialogContent>
                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">
                    <TextField
                        fullWidth
                        label="title"
                        id="title"
                        value={title}
                        disabled={props.mode === 'view'}
                        onChange={(event) => setTitle(event.target.value)}
                    />

                    {props.mode !== 'add' && (
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginBottom: '5px'}}>
                            <Button
                                variant="contained" color={"success"}
                                onClick={() => {
                                    setSwitch_between_original_and_processed(!switch_between_original_and_processed);
                                }}
                            >
                                {switch_between_original_and_processed ? 'Processed' : 'Original'}
                            </Button>
                        </Box>
                    )}
                    {switch_between_original_and_processed && (
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={10}
                            label="orginal"
                            id="original"
                            value={original}
                            disabled={props.mode === 'view' || props.mode === 'edit'}
                            onChange={(event) => setOriginal(event.target.value)}
                        />
                    )}
                    {!switch_between_original_and_processed && (
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={10}
                            label="processed"
                            id="processed"
                            value={processed}
                            disabled={props.mode === 'view'}
                            onChange={(event) => setProcessed(event.target.value)}
                        />
                    )}
                    <Typography variant="h6" gutterBottom component="div"
                                sx={{marginTop: '10px', color: colorSchema.color2}}>
                        Metadata
                    </Typography>

                    <Grid container>
                        {props.mode === 'add' ? (
                            <>
                                <Grid item>
                                    <SelectWithData data={truefalse} id="SelectAddToIndex" onSelect={handleAddToIndex}
                                                    selected={0} label={"Add to knowledge domain"} isOpen={false}
                                                    sx={{m: 1}}/>
                                </Grid>
                                <Grid item>
                                    <SelectWithData data={languageOptions} id="SelectLanguage"
                                                    onSelect={handleLanguageSelected}
                                                    selected={languageOptions.findIndex((option) => option.value === metadataLanguage)}
                                                    label={"Language"} isOpen={false} sx={{m: 1}}/>
                                </Grid>
                                <Grid item>
                                    <SelectWithData data={namespaces} id="SelectCategory"
                                                    onSelect={handleNamespaceSelected}
                                                    selected={namespaces.findIndex((option) => option.value.toLowerCase() === namespace.toLowerCase()) || 0}
                                                    label={"Category"} isOpen={false} sx={{m: 1}}/>
                                </Grid>
                            </>
                        ) : (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                flex: 1,
                                padding: '10px'
                            }}>

                                <Typography variant="h6" sx={{color: colorSchema.color1}}>
                                    Chunks in knowledge database: {props.data.chunks_original}
                                </Typography>
                                <Typography variant="h6" sx={{color: colorSchema.color1}}>
                                    Category: {props.data.namespace}
                                </Typography>
                            </Box>
                        )}
                    </Grid>


                    <TextField
                        fullWidth
                        label="Link or Reference to the resource"
                        id="metadataResource"
                        value={metadataResource}
                        disabled={props.mode === 'view'}
                        onChange={(event) => setMetadataResource(event.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Type of information"
                        id="metadataType"
                        disabled={props.mode === 'view'}
                        value={metadataType}
                        onChange={(event) => setMetadataType(event.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Keywords"
                        id="metadataExtra"
                        disabled={props.mode === 'view'}
                        value={metadataExtra}
                        onChange={(event) => setMetadataExtra(event.target.value)}
                    />

                </Box>

                {props.mode === 'add' && (
                    <Grid item>
                        <div
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            style={{
                                border: `2px dashed ${
                                    dragging ? '#00bcd4' : 'rgba(0, 0, 0, 0.23)'
                                }`,
                                padding: '1rem',
                                marginTop: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <input
                                required
                                id="file"
                                name="file"
                                type="file"
                                accept=".pdf,.doc,.docx,.png, .gif, .jpg, .jpeg"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                            />
                            <label htmlFor="file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    style={{marginRight: '1rem'}}
                                >
                                    Select file
                                </Button>
                                {file ? file.name : 'Drag and drop file here'}
                            </label>
                        </div>
                    </Grid>
                )}

            </DialogContent>
            <DialogActions>
                {props.mode === 'view' ? (
                    <Button autoFocus variant={"contained"} onClick={props.onClose}>
                        Return
                    </Button>
                ) : (
                    <>
                        <Button variant={"contained"} disabled={!isValidForSubmit}
                                onClick={handleSubmit}>Submit</Button>
                        <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                            Cancel
                        </Button>
                    </>
                )}
            </DialogActions>

        </Dialog>
    );
}

// ---------------------------------------------------------------------------------------------------------------
// SearchExtData
// ---------------------------------------------------------------------------------------------------------------
interface SearchExtDataProps {
    projectId: string;
    onClose: () => void;
}

function SearchExtData(props: SearchExtDataProps) {
    const [open, setOpen] = useState(true);
    const [query, setQuery] = useState("");
    const [prompt, setPrompt] = useState("");
    const [filter, setFilter] = useState("");
    const [queryResultList, setQueryResultList] = useState<QueryResult[]>([]);
    const [showActionResult, setShowActionResult] = useState(false);
    const [actionResult, setActionResult] = useState<string[]>([]);
    const [actionResultComplete, setActionResultComplete] = useState(false);
    const [namespace, setNamespace] = useState<string>(namespaces[0].value);
    const [addToIndex, setAddToIndex] = useState<boolean>(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(
        localStorage.getItem('settings_language') || languageOptions[0].value
    );

    function handleSearchClick() {
        setQueryResultList(prevState => []);
        runQuery(props.projectId, query, namespace, filter).then((data: QueryResult[]) => {
            setQueryResultList(data);
        })
    }

    function handlePromptClick() {
        setActionResult(prevState => []);

        let prompt_to_use = prompt;
        if (prompt_to_use === "") {
            prompt_to_use = query;
        }

        if (queryResultList.length === 0) {
            setQueryResultList(prevState => []);
            runQuery(props.projectId, query, namespace, filter).then((data: QueryResult[]) => {
                setQueryResultList(data);
                const context_data = queryResultList.map((result) => result.data).join("\n");
                setActionResult(prevState => [""])
                runGPTRequest(props.projectId, "", selectedLanguage, context_data, prompt_to_use, onDataReceived).then(() => {
                    setActionResultComplete(true)
                })
                setShowActionResult(true);
            })
        } else {
            const context_data = queryResultList.map((result) => result.data).join("\n");
            setActionResult(prevState => [""])
            runGPTRequest(props.projectId, "", selectedLanguage, context_data, prompt_to_use, onDataReceived).then(() => {
                setActionResultComplete(true)
            })
            setShowActionResult(true);
        }
    }

    const onDataReceived = (word: string) => {
        if (word === '\n') {
            // If the received word is a newline character, add a new empty line to the result
            setActionResult((prevResult) => [...prevResult, ""]);
        } else {
            // Otherwise, append the received word to the last line of the action result
            setActionResult((prevResult) => {
                if (prevResult.length === 0) {
                    // If actionResult is empty, initialize it with an empty string
                    return [word];
                } else {
                    const newResult = [...prevResult];
                    newResult[newResult.length - 1] = newResult[newResult.length - 1] + word;
                    return newResult;
                }
            });
        }
    };

    const handleNamespaceSelected = (selection: Option) => {
        setNamespace(selection.value);
    }

    const handleAddToIndex = (selection: Option) => {
        setAddToIndex(prevState => selection.value === 'true');
    }

    const handleLanguageSelected = (selection: Option) => {
        setSelectedLanguage(selection.value);
        localStorage.setItem('settings_language', selection.value);
    }

    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
                <Typography variant={"h4"} sx={{textAlign: 'center', color: colorSchema.color2}}>Search for external
                    data</Typography>
            </DialogTitle>

            <DialogContentText sx={{mx: 1, my: 1}}>
            </DialogContentText>
            <DialogContent>
                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">
                    <TextField
                        fullWidth
                        label="query"
                        id="query"
                        value={query}
                        rows={3}
                        multiline={true}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="prompt"
                        id="prompt"
                        value={prompt}
                        rows={3}
                        multiline={true}
                        onChange={(event) => setPrompt(event.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="filter"
                        id="filter"
                        value={filter}
                        onChange={(event) => setFilter(event.target.value)}
                    />

                    <Grid container>

                        <Grid item>
                            <SelectWithData data={truefalse} id="SelectAddToIndex" onSelect={handleAddToIndex}
                                            selected={0} label={"Add to knowledge domain"} isOpen={false} sx={{m: 1}}/>
                        </Grid>

                        <Grid item>
                            <SelectWithData data={namespaces} id="SelectCategory" onSelect={handleNamespaceSelected}
                                            selected={0} label={"Category"} isOpen={false} sx={{m: 1}}/>
                        </Grid>
                        <Grid item>
                            <SelectWithData data={languageOptions} id="SelectLanguage"
                                            onSelect={handleLanguageSelected}
                                            selected={languageOptions.findIndex((option) => option.value === selectedLanguage)}
                                            label={"Language"} isOpen={false} sx={{m: 1}}/>

                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <ShowQueryResults queryResultList={queryResultList}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                    Return
                </Button>
                <Button variant={"contained"} autoFocus onClick={handleSearchClick} disabled={query.trim().length == 0}>
                    Search
                </Button>
                <Button variant={"contained"} autoFocus
                        disabled={query.trim().length === 0 && prompt.trim().length === 0} onClick={handlePromptClick}>
                    Run Prompt
                </Button>
            </DialogActions>

            {/* -----------------------------------------------------------------------------  */}
            {/* Show the chatGPT request feedback                                              */}
            {/* -----------------------------------------------------------------------------  */}
            {showActionResult && (
                <Dialog
                    open={showActionResult}
                    onClose={() => {
                        setShowActionResult(false)
                    }}
                    PaperComponent={DraggablePaper}
                    sx={{
                        left: '100px',
                        top: '10px',
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle variant={"h4"} id="alert-dialog-title"
                                 sx={{
                                     borderBottom: "1px solid black",
                                     marginBottom: "20px",
                                     color: colorSchema.color3
                                 }}>
                        Accessing the knowledge base to obtain information
                    </DialogTitle>
                    <DialogContent>
                        {actionResult.map((line, index) => {
                            if (line.trim() === '') {
                                return <br key={index}/>;
                            }
                            return (
                                <Typography sx={{marginBottom: '2px', textAlign: 'justify'}} key={index}>
                                    {line}
                                </Typography>
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        {actionResultComplete && (
                            <Button color={"warning"} variant={"contained"} onClick={() => {
                                setShowActionResult(false)
                            }}>Return</Button>
                        )}
                    </DialogActions>
                </Dialog>
            )
            }
        </Dialog>
    )
}

// ---------------------------------------------------------------------------------------------------------------
// SearchExtData
// ---------------------------------------------------------------------------------------------------------------
interface ShowQueryResultsProps {
    queryResultList: QueryResult[];
}

function ShowQueryResults(props: ShowQueryResultsProps) {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <div>
            {props.queryResultList.length !== 0 && (
                <Typography variant={"h4"} sx={{textAlign: 'center', color: colorSchema.color1}}>Matching
                    Chunks</Typography>
            )}

            {props.queryResultList.map((result, index) => (
                <Accordion key={index} expanded={expanded === 'r' + index} onChange={handleChange('r' + index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={"result_" + index}
                        id={"result_" + index}
                    >
                        <Typography>{result.title} - {result.score}%</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {result.data}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}

interface SiteListProps {
    projectId: string;
    onClose: () => void;
    onImport: (namespace: string, siteMap: string[], addToIndex: boolean) => void;
    open: boolean; // Add the open prop
}

function SiteList(props: SiteListProps) {
    const {projectId, onClose, open: initialOpen} = props; // Destructure the props
    const [open, setOpen] = useState(initialOpen);
    const [url, setUrl] = useState('');
    const [siteMap, setSiteMap] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [showSiteMap, setShowSiteMap] = useState(false);
    const [namespace, setNamespace] = useState(namespaces[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [userAlert, setUserAlert] = useState("");
    const [addToIndex, setAddToIndex] = useState<boolean>(false);

    const handleLoadSiteMapClick = () => {

        // Reset the state
        setUserAlert("");
        setIsLoading(true);
        setItemsLoaded(false);
        setShowSiteMap(false);

        let sitelist_url = url;
        const urlPattern = /^https?:\/\//i; // Regular expression pattern for "http://" or "https://"

        if (!urlPattern.test(url)) {
            sitelist_url = "https://" + url; // Add "https://" prefix if not present
        }

        // Get SiteList
        getSiteList(projectId, sitelist_url).then((result) => {

            setSiteMap(result);
            setIsLoading(false);

            if (result.length > 0) {
                setItemsLoaded(true);
                setShowSiteMap(true);
            } else {
                setUserAlert("No site map found for this URL. Please try another URL.")
            }
        })
    }

    const handleImportClick = () => {

        if (namespace !== '') {

            if (selectedItems.length === 0) {
                setUserAlert("Please select at least one item to import.")
                return;
            }

            props.onImport(namespace, siteMap.filter((_, index) => selectedItems.includes(index)), addToIndex);
        }
    }

    const handleSelectionChange = (selectedItems: number[]) => {
        setSelectedItems(selectedItems);
        console.log("Selected items:", selectedItems);
    };


    const handleNamespaceSelected = (selection: Option) => {
        console.log(selection);
        setNamespace(selection.value);
    }

    const handleAddToIndex = (selection: Option) => {
        setAddToIndex(prevState => selection.value === 'true');
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
                <Typography variant={"h4"} color={colorSchema.color2}>Load SiteMap</Typography>
            </DialogTitle>

            <DialogContentText sx={{mx: 0, my: 0}}></DialogContentText>
            <DialogContent>
                <Grid container>
                    <Grid item xs={3}>
                        <SelectWithData data={truefalse} id="SelectAddToIndex" onSelect={handleAddToIndex}
                                        selected={0} label={"Add to knowledge domain"} isOpen={false}
                                        sx={{mr: 1, mb: 1}}
                        />

                    </Grid>
                    <Grid item xs={9}>
                        <SelectWithData data={namespaces} id="SelectCategory" onSelect={handleNamespaceSelected}
                                        selected={0} label={"Category"} isOpen={false} sx={{mb: 1}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Enter the URL of the site map</Typography>
                        <TextField
                            fullWidth
                            label="URL"
                            id="url"
                            value={url}
                            onChange={(event) => setUrl(event.target.value)}
                        />
                    </Grid>


                </Grid>
                <Box>
                    {showSiteMap && <ShowList list={siteMap} onSelectionChange={handleSelectionChange}/>}
                </Box>

                {userAlert !== "" && (
                    <Alert onClose={() => setUserAlert("")}>{userAlert}</Alert>
                )}

            </DialogContent>
            <DialogActions>

                {!isLoading ? (
                    <Button variant="contained" autoFocus onClick={handleLoadSiteMapClick}>
                        Load SiteMap
                    </Button>
                ) : (
                    <Button variant="contained" disabled>
                        Loading....
                    </Button>
                )}

                <Button variant="contained" autoFocus onClick={handleImportClick} disabled={!itemsLoaded}>
                    Import
                </Button>
                <Button variant="contained" color={"warning"} autoFocus onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}


// ---------------------------------------------------------------------------------------------------------------
// SearchExtData
// ---------------------------------------------------------------------------------------------------------------
interface ShowListProps {
    list: string[];
    onSelectionChange: (selectedItems: number[]) => void;
}

function ShowList(props: ShowListProps) {
    const [recordList, setRecordList] = React.useState<string[]>(props.list);
    const [checked, setChecked] = React.useState<number[]>([]);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        props.onSelectionChange(newChecked);
    };

    return (
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            {recordList.map((item, index) => (
                <ListItem key={"item_" + index} disablePadding>
                    <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(index) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{'aria-labelledby': `checkbox-list-label-${index}`}}
                            />
                        </ListItemIcon>
                        <ListItemText id={`checkbox-list-label-${index}`} primary={item}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}





