import React, {Component, useEffect, useState} from 'react';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

interface ControlledEditorProps {
    content: string,
    onEditorStateChange: (editorState: EditorState) => void
}

export const ControlledEditor = (props: ControlledEditorProps) => {

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [content, setContent] = useState<string>("");

    // When the content changes, update the editorState
    useEffect(() => {
        let {contentBlocks, entityMap} = htmlToDraft(props.content);

        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const _editorState = EditorState.createWithContent(contentState);

        setEditorState(_editorState);
    }, [props.content]);

    function handleEditorStateChange(newState: EditorState) {
        setEditorState(newState);
        props.onEditorStateChange(newState);
        // setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
    }

    return (
        <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={handleEditorStateChange}
        />
    )
}