import {Application} from "../../backend/core.services";

export let selectedLanguage = 'nl';
export let debugMode: boolean = true;
export let currentProject = "";
export let currentOrganization = "";
export let currentApplication = "";
export let accessToken = ""
export let applicationList: Application[] = [];

export function setApplicationList(list: Application[]) {
    console.log("setApplicationList", list);
    applicationList = list;
}

export function setCurrentProject(projectUuid: string) {
    console.log("setCurrentProject", projectUuid);
    currentProject = projectUuid;
}

export function setCurrentOrganization(organizationUuid: string) {
    console.log("setCurrentOrganization", organizationUuid);
    currentOrganization = organizationUuid;
}

export function setCurrentApplication(applicationUuid: string) {
    console.log("setCurrentApplication", applicationUuid);
    currentApplication = applicationUuid;
}

export function updateAccessToken(token: string) {
    console.log("updateAccessToken", token);
    accessToken = token;
}

export function updateSelectedLanguage(language: string) {
    selectedLanguage = language;
    console.log("updateSelectedLanguage", language);
    localStorage.setItem('BoostrFoundation_SelectedLanguage', language);
}

export interface Option {
    value: string;
    label: string;
}

export const languageOptions: Option[] = [
    {value: "nl", label: "Dutch"},
    {value: "en", label: "English"},
    {value: "fr", label: "French"},
    {value: "de", label: "Deutsch"},
];

