import config from '../config.json';
import axios from "axios";
import {ExtDataRecord} from "./extdata.services";
const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

// HANDLE LogIn Request : Connect to the ChatBoostr server, with the given user and password and retrieve a token.
//------------------------------------------------------------------------------------------------------------------------------------
export interface User {
  uuid: string;
  user_name: string;
  contact: Contact;
  props: string;
}

export interface Contact {
  uuid: string;
  first_name: string;
  last_name: string;
  description: string;
  phone_number: string;
  email_address: string;
  address: string;
  zip_code: string;
  location: string;
  country: string;
  status: string;
}


export async function GetUserList(organisationId: string): Promise<User[]> {

      const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};

    let url = service_url + `/main/organisation/${organisationId}/user/all`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as User[];
        })
        .catch(error => {
            console.error(error);
            return [] as User[];
        });
}

