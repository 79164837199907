import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, useRouteError, RouterProvider} from "react-router-dom";
import RootLayout from "./layouts/RootLayout";

import {
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";

import Home from "./routes/Main.page";
import ChatBoostr from "./routes/ChatBoostr.page";
import DataBoostrPage from "./routes/DataBoostr.page";
import TalentBoostrPage from './routes/TalentBoostr.page'
import BoostrManagementPage from './routes/Boostr.management.page';
import UIDemoPage from "./routes/UIDemo.page";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout/>} errorElement={<ErrorBoundary/>}>
            <Route index element={<Home message={"Home"}/>}/>
            <Route path="BoostrManagement" element={<BoostrManagementPage message={""}/>}/>
            <Route path="ChatBoostr" element={<ChatBoostr message={""}/>}/>
            <Route path="DataBoostr" element={<DataBoostrPage message={""}/>}/>
            <Route path="TalentBoostr" element={<TalentBoostrPage message={""}/>}/>
            <Route path="Demo" element={<UIDemoPage/>}/>
        </Route>
    )
);

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <div>Dang!</div>;
}

function App() {
    return (
        <RouterProvider router={router}/>
    )
}

const root = createRoot(document.getElementById('root') as Element);
const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            {/*<ManifestContext.Provider value={manifest}>*/}
            <App/>
            {/*</ManifestContext.Provider>*/}
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
