import {createTheme} from "@mui/material";

export const colorSchema = {
    color1: '#575655',
    color2: '#D5781E',
    color3: '#D1AA84',
    color4: '#1D82A1',
    color5: "#49B1D1",
    bg_titles: "#8A8A8A",
    tekst: "rgb(0 0 0 / 60%)",
    info: "#2A4F87",
    warning: "#F18F01",
    success: "#428959",
    error: "#B85042",

    primary: "#3E4D5C",
    secondary: "#E7E8D1",
    primary_support: "#A7BEAE",

    primary1: "#C06010",
    primary2: "#D5781E",
    primary3: "#D5781E",

    gray1: "#203040",
    gray2: "#304050",
    gray3: "#405060",
    gray4: "#506070",
    gray5: "#607080",
    gray6: "#708090",
    gray7: "#8090A0",
    gray8: "#90A0B0",
    gray9: "#A0B0C0",
    gray10: "#B0C0D0",
    gray11: "#C0D0E0",
    gray12: "#D0E0F0",


};

export const ChatBoostrTheme = createTheme({
    palette: {
        primary: {
            main: colorSchema.primary,
            light: colorSchema.primary,
            dark: colorSchema.primary,
            contrastText: '#fff',
        },
        secondary: {
            main: colorSchema.secondary,
            light: colorSchema.secondary,
            dark: colorSchema.secondary,
            contrastText: '#fff',
        },
        warning: {
            main: colorSchema.warning,
            light: colorSchema.warning,
            dark: colorSchema.warning,
            contrastText: '#fff',
        },
        success: {
            main: colorSchema.success,
            light: colorSchema.success,
            dark: colorSchema.success,
            contrastText: '#fff',
        },
    },
    direction: 'rtl',
    typography: {
        fontWeightRegular: 100,
        fontFamily: [
            'montserrat',
            '"Segoe UI"',
            '-apple-system',
            'BlinkMacSystemFont',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h6: {
            fontSize: '1.00rem',
            fontWeight: 100,
        },
    },

});



