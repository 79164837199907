//=================================================================================================================
// <RecruitmentApp/> COMPONENT
//=================================================================================================================
import DialogTitle from "@mui/material/DialogTitle";
import {colorSchema} from "../../data/theme";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {Contact, User} from "../../backend/management.services";
import {useEffect} from "react";
import {TextField, Typography} from "@mui/material";

export interface UserDialogProps {

    mode: "create" | "update" | "delete" | "view";
    user: User | undefined;
    onDeleted: (user: User) => void;
    onUpdate: (user: User) => void;
    onCreated: (user: User) => void;
    onClose: () => void;
}

// ---------------------------------------------------------------------------------------------------------------
export function UserDialog(props: UserDialogProps) {

    const [mode, setMode] = React.useState<"create" | "update" | "delete" | "view">(props.mode);
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState<boolean>(['update', 'create'].includes(mode));
    const [user, setUser] = React.useState<User>(props.user || {
        uuid: '',
        user_name: '',
        contact: {
            uuid: '',
            first_name: '',
            last_name: '',
            description: '',
            phone_number: '',
            email_address: '',
            address: '',
            zip_code: '',
            location: '',
            country: '',
            status: '',
        },
        props: '',
    });

    useEffect(() => {
        setIsLoaded(true);
        validateUser();
    }, []);

    function validateUser() {

        let email_valid = true;
        // Validate email using a simple regex pattern
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailPattern.test(user.contact.email_address)) {
            email_valid = false;
        }

        setIsValid(email_valid && user.contact.first_name !== '' && user.contact.last_name !== '' && user.contact.email_address !== '');
    }

    function handleSubmit() {

        if (mode === "create") {
            return props.onCreated(user);
        }
        if (mode === "update") {
            return props.onUpdate(user);
        }
        if (mode === "delete") {
            return props.onDeleted(user);
        }
    }

    function handleClose() {
        setIsOpen(false);
        props.onClose();
    }

    const handleFieldChange = (path: string[], value: any) => {

        setUser((prevUser: User) => {
            const newUser = JSON.parse(JSON.stringify(prevUser)); // Deep clone

            // Navigate to the right field
            let fieldToUpdate: any = newUser;
            for (let i = 0; i < path.length - 1; i++) {
                fieldToUpdate = fieldToUpdate[path[i]];
            }

            // Update the value
            fieldToUpdate[path[path.length - 1]] = value;

            return newUser as User;
        });
        validateUser();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="userDialogTitle" align={"center"} color={colorSchema.color1}>
                {props.mode.charAt(0).toUpperCase() + props.mode.slice(1) + " User"}
            </DialogTitle>
            <DialogContent>
                <Typography variant={"h6"} color={colorSchema.color2} marginBottom={1}>Name</Typography >
                <TextField fullWidth label="First Name" id="first name" value={user.contact.first_name}
                           onChange={(event) => handleFieldChange(['contact', 'first_name'], event.target.value)}
                           disabled={!isEditable}/>

                <TextField fullWidth label="Last Name" id="last name" value={user.contact.last_name}
                           sx={{marginTop: '10px'}}
                           onChange={(event) => handleFieldChange(['contact', 'last_name'], event.target.value)}
                           disabled={!isEditable}/>

                <Typography variant={"h6"} marginTop={2} marginBottom={1} color={colorSchema.color2}>E-mail</Typography>
                <TextField fullWidth label="E-mail" id="email" value={user.contact.email_address}
                           onChange={(event) => handleFieldChange(['contact', 'email_address'], event.target.value)}
                           disabled={!isEditable}/>

                <Typography variant={"h6"} marginTop={2} marginBottom={1} color={colorSchema.color2}>Description</Typography>
                <TextField fullWidth multiline rows={3} label="Description" id="description"
                           value={user.contact.description}
                           onChange={(event) => handleFieldChange(['contact', 'description'], event.target.value)}
                           disabled={!isEditable}/>

                <Typography variant={"h6"} marginTop={2} marginBottom={1} color={colorSchema.color2}>Phone</Typography>
                <TextField fullWidth label="Phone" id="phone" value={user.contact.phone_number}
                           onChange={(event) => handleFieldChange(['contact', 'phone_number'], event.target.value)}
                           disabled={!isEditable}/>

                <Typography variant={"h6"} marginTop={2} marginBottom={1} color={colorSchema.color2}>Status</Typography>
                <TextField fullWidth label="Status" id="status" value={user.contact.status}
                           onChange={(event) => handleFieldChange(['contact', 'status'], event.target.value)}
                           disabled={!isEditable}/>

            </DialogContent>
            <DialogActions>
                <Button color={"warning"} onClick={() => handleClose()}>Cancel</Button>
                <Button color={"success"} disabled={!isValid} onClick={() => handleSubmit()} autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
