import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {colorSchema} from "../../data/theme";


interface AlertDialogProps {
    open: boolean
    title: string;
    message: string;
    onSubmit: (isConfirmed:boolean) => void;
}

export default function AlertDialog(props: AlertDialogProps) {
    const [open, setOpen] = React.useState(props.open);

    function onSubmit( isConfirmed: boolean ) {
        setOpen(false);
        props.onSubmit(isConfirmed);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onSubmit(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" align={"center"} color={colorSchema.color1} >
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" textAlign={"justify"}>
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={"warning"} onClick={() => onSubmit(false)}>Cancel</Button>
                <Button color={"success"} onClick={() => onSubmit(true)} autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}