import React, {useEffect, useRef, useState} from "react";
import {useRouteError, useSearchParams} from "react-router-dom";
import {Box, Button, ButtonGroup, Container, Grid, List, ListItem, TablePagination, Typography} from "@mui/material";
import {colorSchema} from "../data/theme";
import ChatBoostrMenu from "../components/chatboostr/ChatBoostrMenu";
import {GetUserList, User} from "../backend/management.services";
import {SelectWithData} from "../components/general/Site.snippets";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import DeleteIcon from "@mui/icons-material/Delete";
import ExtDataPageViewer from "../components/extdata/HandlePageInteraction";
import {ExtDataRecordDetail} from "../components/extdata/ListExtDataRecords";
import {ExtDataRecord} from "../backend/extdata.services";
import {UserDialog} from "../components/managements/UserManagementDialog";

interface BoostrManagementPageProps {
    message: string;
}

export default function BoostrManagementPage(props: BoostrManagementPageProps) {

    const [organizationId, setOrganizationId] = useState<string>('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchParams] = useSearchParams();


    // Load URL parameter and initiate page view.
    useEffect(() => {
        console.log('Loading BoostrManagement');

        const params: any = {};
        searchParams.forEach((value, key) => {
            params[key] = value;
            console.log(`[${key}] = ${value}`);
        });

        setOrganizationId(params['organizationId']);
        setIsLoaded(true);
    }, [searchParams]);


    return (
        <Container maxWidth="xl" sx={{marginTop: '30px'}}>
            <Typography variant={"h3"} sx={{
                color: colorSchema.primary,
                textAlign: "center",
                marginTop: -2,
                marginBottom: -2
            }}>Component Management Console</Typography>
            {isLoaded && (
                <ShowUsers organizationId={organizationId}/>
            )}
        </Container>
    );
}


interface ShowUsersProps {
    organizationId: string;
}

function ShowUsers(props: ShowUsersProps) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [recordList, setRecordList] = useState<User[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showSiteDialog, setShowSiteDialog] = useState(false);
    const [showPagesDialog, setShowPagesDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [searchDialog, setSearchDialog] = useState(false);
    const [selectedRecordIndex, setSelectedRecordIndex] = useState(-1); // Initialize with -1
    const [addUserDialog, setAddUserDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User|undefined>(undefined);

    const [mode, setMode] = useState<"create" | "update" | "delete" | "view">("view");

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);/**/

    useEffect(() => {
        GetUserList(props.organizationId).then((response) => {
            setRecordList(response);
            setIsLoaded(true);
        });
    }, [props.organizationId]); // Added dependency for effect to re-run when projectId changes

    // return (
    //     <Box>
    //         {isLoaded && (
    //             <List key={"appList_" + props.organizationId}>
    //                 { users.map((user) => (
    //                 <ListItem key={user.uuid}> {/* Add unique key for list items */}
    //                     <Typography variant={"h4"} sx={{
    //                         color: colorSchema.primary,
    //                         textAlign: "center",
    //                         marginTop: -2,
    //                         marginBottom: -2
    //                     }}>{user.user_name}</Typography>
    //                 </ListItem>
    //                 ))}
    //             </List>
    //         )}
    //     </Box>
    // );

    function sortRecordList(selectedField: keyof User): void {
        setRecordList((prevList) => {
            // Create a copy of the recordList to avoid mutating the original state
            const newList = [...prevList];

            newList.sort((a, b) => {
                // Use the selected field to access the corresponding values in the objects
                const valueA = a[selectedField];
                const valueB = b[selectedField];

                // Compare the values and return -1, 0, or 1 based on the comparison
                if (valueA < valueB) {
                    return -1;
                } else if (valueA > valueB) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return newList;
        });
    }

    const handleManageUserPressed = (mode: "create" | "update" | "delete" | "view", user: User | undefined) => {
            setMode(mode);
            setSelectedUser(user);
            setAddUserDialog(true);
    }

    const handleUserCreated = (user:User) => {
        setRecordList((prevList) => {
            const newList = [...prevList];
            newList.push(user);
            return newList;
        });
        setAddUserDialog(false);
    }

    const handleUserUpdated = (user:User) => {
        setRecordList((prevList) => {
            const newList = [...prevList];
            const index = newList.findIndex((item) => item.uuid === user.uuid);
            if (index >= 0) {
                newList[index] = user;
            }
            return newList;
        });
        setAddUserDialog(false);
    }

    const handleUserDeleted = (user:User) => {
        setRecordList((prevList) => {
            const newList = [...prevList];
            const index = newList.findIndex((item) => item.uuid === user.uuid);
            if (index >= 0) {
                newList.splice(index, 1);
            }
            return newList;
        });
        setAddUserDialog(false);
    }

    // Handle pagination
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleRowsPerPage = (event: any, rows: React.SetStateAction<number>) => {
        setRowsPerPage(rows);
    };

    // Perform actions on the selected record (view, edit or delete).
    function handleRecordClick(index: number, selected_mode: "create" | "update" | "delete" | "view") {
        setMode(selected_mode)
        setSelectedUser(recordList[index]);
        setAddUserDialog(true);
    }

    return (

        <Grid container sx={{margin: '2px', marginTop: '10px'}}>

            {addUserDialog && (
                <UserDialog mode={mode} user={selectedUser} onClose={() => setAddUserDialog(false)}
                            onDeleted={handleUserDeleted}
                            onUpdate={handleUserUpdated}
                            onCreated={handleUserCreated}
                            />
            )}

            <Grid item xs={3}>
                <Box>

                </Box>
                {/*<SelectWithData data={namespaces} id="SelectCategory" onSelect={handleNamespaceSelected}*/}
                {/*                selected={namespaces.findIndex((option) => option.value === namespace)}*/}
                {/*                label={"Category"} isOpen={false} sx={{*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "row",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "start",*/}
                {/*    m: 1,*/}
                {/*}}/>*/}
            </Grid>
            <Grid item xs={9} sx={{textAlign: "end", alignSelf: "center"}}>
                <ButtonGroup sx={{marginBottom: '10px'}} size="small" variant="contained">
                    <Button onClick={() => handleManageUserPressed("create", undefined)}>
                        Add
                    </Button>
                    <Button onClick={() => {}}>
                        Manage Projects
                    </Button>
                </ButtonGroup>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="ExtDataRecordList">
                        <TableHead>
                            <TableRow sx={{backgroundColor: colorSchema.color4, color: 'white'}}>
                                <TableCell sx={{color: 'inherit'}}
                                           onClick={() => sortRecordList('user_name')}>User</TableCell>
                                <TableCell sx={{color: 'inherit'}}
                                           onClick={() => sortRecordList('contact')}>E-mail</TableCell>
                                <TableCell sx={{color: 'inherit'}} align="right">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recordList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row"
                                               onClick={() => handleRecordClick(index + (page * rowsPerPage), "view")}
                                    >
                                        {record.user_name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {record.contact.email_address}
                                    </TableCell>
                                    <TableCell align="right">
                                        <EditIcon
                                            onClick={() => handleRecordClick(index + (page * rowsPerPage), "update")}/>
                                        <FindInPageIcon sx={{color: 'green'}}
                                                        onClick={() => {}}/>
                                        <DeleteIcon sx={{color: 'red'}}
                                                    onClick={() => {
                                                    }}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={recordList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={event => {
                        setRowsPerPage(parseInt(event.target.value, 10))
                        setPage(0)
                    }}
                />

            </Grid>
        </Grid>
    );
}

