import React, {useState} from 'react'
import {Authenticate, AuthenticateResponse} from "../../backend/authentication.services";
import {
    Alert,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Grid
} from '@mui/material'


export interface LogInProps {
    isOpen: boolean;
    onSucces: (newAccessToken: string) => void;
    onCancel: () => void;
}

export const LogIn = ({isOpen, onSucces, onCancel}: LogInProps) => {

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")

    // Handle button interaction
    const handleSubmit = async () => {
        setErrorMessage("");
        Authenticate(username, password).then((response: AuthenticateResponse) => {
            if (response.status == 200 && response.token != "") {
                localStorage.setItem('BoostrFoundation_AccessToken', response.token);
                onSucces(response.token)
            } else {
                if (response.status == 401) {
                    setErrorMessage("Authentication failed. Please check your username and password.")
                } else {
                    setErrorMessage(`Server error ${response.status} received. Contact support for help.`)
                }
            }
        });
    };

    const handleClose = () => {
        onCancel()
    };

    // Handle input field changes
    const handleUsernameChange = (username: string) => {
        setUsername(username)
    }
    const handlePasswordChange = (password: string) => {
        setPassword(password)
    }

    const draggablePaperStyle = {
        bgcolor: 'background.paper',
        minWidth: '400px',
        border: '2px solid #000',
        boxShadow: 24,
        resize: 'both',
        p: 4,
    };


    return (

        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle sx={{cursor: 'move'}} id="draggable-dialog-title">
                ZAIA Managagement Console - Authentication
            </DialogTitle>
            <DialogContentText sx={{mx: 5, my: 3}}>
                Please enter your username and password and press submit.
            </DialogContentText>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="username"
                            label="username"
                            multiline
                            maxRows={1}
                            onChange={(event: { target: { value: string; }; }) => handleUsernameChange(event.target.value)}
                        />
                    </Grid>
                    <Divider/>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="password"
                            label="password"
                            type="password"
                            onChange={(event: { target: { value: string; }; }) => handlePasswordChange(event.target.value)}
                        />
                    </Grid>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sx={{my: 5}}>
                    {errorMessage && (
                        <Alert severity="error">{errorMessage}</Alert>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} autoFocus onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>

    );
}

