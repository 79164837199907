import React, {useEffect, useState} from "react";
import {colorSchema} from "../../data/theme";
import {
    Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, CircularProgress, Divider, Grid, Menu, MenuItem,
    Select, Tab, Tabs, TextField, Typography
} from "@mui/material";
import {
    getMenus,
    getTopics,
    getServices,
    createMenu,
    createTopic,
    createService,
    deleteMenu,
    deleteTopic,
    deleteService,
    updateMenu,
    updateTopic,
    updateService,
    CbMenu,
    CbTopic,
    CbService,
    getDetachedServices,
    getDetachedTopics,
    CbMenuOut,
    CbTopicOut,
    CbServiceOut,
    detachServiceFromTopic,
    attachServiceToTopic
} from "../../backend/chatboostr.services";
import ManageItem, {CreateItem} from "./ManageItem";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ServiceList from "./ServiceList";
import AlertDialog from "../general/AlertDialog";

export interface Item {
    id: string;
    type: 'menu' | 'topic' | 'service' | "";
    title: string;
    description: string;
    actionStart: string;
    actionEnd: string;
    actionStartType: string;
    actionEndType: string;
    sequence: number;
    language: string;
}

interface ChatBoostrMenuProps {
    projectId: string;
}

export default function ChatBoostrMenu(props: ChatBoostrMenuProps) {

    const [projectId, setProjectId] = useState<string>(props.projectId);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [menuList, setMenuList] = useState<CbMenu[]>([]);

    const [topicList, setTopicList] = useState<CbTopic[]>();
    const [serviceList, setServiceList] = useState<CbService[]>();
    const [detachedServiceList, setDetachedServiceList] = useState<CbService[]>();

    const [selectedMenuId, setSelectedMenuId] = useState<string | undefined>();
    const [selectedServiceId, setSelectedServiceId] = useState<string | undefined>();
    const [selectedTopicId, setSelectedTopicId] = useState<string | undefined>();

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [showCreateView, setShowCreateView] = useState(false);
    const [createMode, setCreateMode] = useState<'menu' | 'topic' | 'service' | ''>('');

    const [showServiceList, setShowServiceList] = useState(false);
    const [serviceListMode, setServiceListMode] = useState("");
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [redrawCounter, setRedrawCounter] = useState(0);


    const [selectedItem, setSelectedItem] = useState<Item>({
        id: "",
        type: "",
        title: "",
        description: "",
        actionStart: "",
        actionEnd: "",
        actionStartType: "",
        actionEndType: "",
        sequence: 0,
        language: ""
    });
    const [showSelectedItem, setShowSelectedItem] = useState(false);
    const [itemList, setItemList] = useState<Item[]>([]);

    const [reloadMenu, setReloadMenu] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleTopicMenuClicked = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleTopicMenuCancel = () => {
        setAnchorEl(null);
    };

    // ------- Retrieve menus at page load -------
    useEffect(() => {
        console.log("Boostr Foundation Loaded...")
        console.log(props);

        getMenus(props.projectId).then((data: CbMenu[]) => {
            setMenuList(prevState => data);
            if (data.length > 0) {
                setSelectedMenuId(data[0].uuid);
            }
            setIsLoaded(true);
            setReloadMenu(false);
        })
    }, [projectId])

    // ----- Retrieve "topics" when the user selects one. -----
    useEffect(() => {
        console.log(`Menu Selected: ${selectedMenuId}`)
        if (selectedMenuId !== undefined) {
            setTopicList(prevState => undefined);
            getTopics(props.projectId, selectedMenuId).then((topicList: CbTopic[]) => {

                // sort topicList by sequence
                topicList.sort(sortTopicList);
                setTopicList(prevState => topicList);
                if (topicList.length > 0) {
                    showTopicDetails(topicList[0].uuid);
                }
            })
        }
    }, [selectedMenuId, reloadMenu])

    // ------- Retrieve "services" when the user selects one -------
    useEffect(() => {
        console.log(`Topic Selected: ${selectedTopicId}`)
        if (selectedTopicId !== undefined && selectedMenuId !== undefined) {
            setServiceList(prevState => undefined);
            getServices(props.projectId, selectedMenuId, selectedTopicId).then((serviceList: CbService[]) => {

                // sort serviceList by sequence, then by title
                serviceList.sort(sortServiceList);
                setServiceList(prevState => serviceList);
                setReloadMenu(false);
            })
        }
    }, [selectedTopicId])

    // --- SORT FUNCTIONS ---
    function sortServiceList(a: CbService, b: CbService): number {
        if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence; // Sort by sequence if they are different
        } else {
            return a.title.localeCompare(b.title); // Sort by title if sequence is the same
        }
    }

    function sortTopicList(a: CbTopic, b: CbTopic): number {
        if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence; // Sort by sequence if they are different
        } else {
            return a.title.localeCompare(b.title); // Sort by title if sequence is the same
        }
    }


    // CLEAR INPUT FIELDS, FLAGS, MENUS
    const clearActions = () => {
    }

    // SHOW SERVICE DETAILS SELECTED
    const showServiceDetails = (serviceId: string): void => {

        const selectedService: CbService | undefined = serviceList?.find((service: CbService) => service.uuid === serviceId);
        if (selectedService) {

            setSelectedItem({
                ...selectedItem,
                id: selectedService.uuid,
                type: "service",
                title: selectedService.title,
                description: selectedService.description,
                actionStart: selectedService.action_start,
                actionEnd: selectedService.action_end,
                actionStartType: selectedService.action_start_type,
                actionEndType: selectedService.action_end_type,
                sequence: selectedService.sequence,
                language: 'en'
            })

            setSelectedServiceId(serviceId);

            console.log("Selected Service");
            console.log(selectedItem);

            // clearActions();
            // setShowSelectedItem(true);
        }
    }

    // SHOW SERVICE DETAILS SELECTED
    const showTopicDetails = (topicId: string): void => {

        // search selected topic in topicList
        const selectedTopic: CbTopic | undefined = topicList?.find((topic) => topic.uuid === topicId);
        if (selectedTopic) {

            setSelectedItem({
                ...selectedItem,
                id: selectedTopic.uuid,
                type: "topic",
                title: selectedTopic.title,
                description: selectedTopic.description,
                actionStart: "",
                actionEnd: "",
                actionStartType: "",
                actionEndType: "",
                sequence: selectedTopic.sequence,
                language: 'en'
            })

            setSelectedTopicId(topicId);
            setSelectedServiceId("");
        }
    }

    function handleServiceSelected(action: string, serviceId: string, i: number) {

        // search selected service in serviceList
        const selectedService: CbService | undefined = detachedServiceList?.find((service) => service.uuid === serviceId);
        if (selectedService && selectedMenuId && selectedTopicId) {
            attachServiceToTopic(projectId, selectedMenuId, selectedTopicId, serviceId).then((serviceList: CbService[]) => {
                serviceList.sort(sortServiceList);
                setServiceList(prevState => serviceList);
            })
        }
        setShowServiceList(false);
    }

    function handleAssignServiceAction() {
        getDetachedServices(props.projectId).then((detachedServiceList: CbService[]) => {
            setDetachedServiceList(detachedServiceList);
            setShowServiceList(true);
            setServiceListMode("Assign");
            setAnchorEl(null);
        })
    }

    function handleCreateRequest(type: 'menu' | 'topic' | 'service' | '') {
        setCreateMode(type);
        setShowCreateView(true);
    }

    function processCreateItem(item: Item) {

        setShowCreateView(false)

        if (item.type === 'menu') {
            const new_menu: CbMenuOut = {
                title: item.title, description: item.description,
                sequence: item.sequence, language_code: item.language
            }

            setIsLoaded(false);
            createMenu(projectId, new_menu).then((menuList: CbMenu[]) => {
                setMenuList(prevState => menuList);

                // find menu based on title
                const menu = menuList.find(menu => menu.title === item.title);
                if (menu !== undefined) {
                    setSelectedMenuId(menu.uuid);
                }
                setIsLoaded(true);
            })
        }

        if (item.type === 'topic') {
            const new_topic: CbTopicOut = {
                title: item.title,
                description: item.description,
                sequence: item.sequence,
                language_code: item.language
            }

            if (selectedMenuId === undefined) {
                console.log("No menu selected")
                return;
            }

            setIsLoaded(false);
            createTopic(projectId, selectedMenuId, new_topic).then((topicList: CbTopic[]) => {
                topicList.sort(sortTopicList);
                setTopicList(prevState => topicList);

                // find topic based on title
                const topic = topicList.find(topic => topic.title === item.title);
                if (topic !== undefined) {
                    setSelectedTopicId(topic.uuid);
                }

                setIsLoaded(true);
            })
        }

        if (item.type === 'service') {
            const new_service: CbServiceOut = {
                title: item.title,
                description: item.description,
                sequence: item.sequence,
                language_code: item.language,
                action_end: item.actionEnd,
                action_end_type: item.actionEndType,
                action_start: item.actionStartType,
                action_start_type: item.actionStartType
            }

            if (selectedMenuId === undefined || selectedTopicId === undefined) {
                console.log("No menu or topic selected")
                return;
            }

            setServiceList(prevState => undefined);
            setIsLoaded(false);
            createService(projectId, selectedMenuId, selectedTopicId, new_service).then((serviceList: CbService[]) => {

                serviceList.sort(sortServiceList);
                setServiceList(prevState => serviceList);

                // find service based on title
                const service = serviceList.find(service => service.title === item.title);
                if (service !== undefined) {
                    setSelectedServiceId(service.uuid);
                }

                setIsLoaded(true);
            })
        }
    }

    function processManageItem(mode: string, item: Item) {

        if (mode === 'delete') {
            if (item.type === 'service') {
                if (selectedMenuId === undefined || selectedTopicId === undefined) {
                    console.log("No menu or topic selected")
                    return;
                }
                deleteService(projectId, selectedMenuId, selectedTopicId, item.id).then((serviceList: CbService[]) => {
                    serviceList.sort(sortServiceList);
                    setServiceList(prevState => serviceList);
                })
            } else if (item.type === 'topic') {
                if (selectedMenuId === undefined) {
                    console.log("No menu selected")
                    return;
                }
                deleteTopic(projectId, selectedMenuId, item.id).then((topicList: CbTopic[]) => {
                    topicList.sort(sortTopicList);
                    setTopicList(prevState => topicList);
                })

            } else if (item.type === 'menu') {
                deleteMenu(projectId, item.id).then((menuList: CbMenu[]) => {
                    setMenuList(prevState => menuList);
                })
            }
        }

        if (mode === 'detach') {
            if (item.type === 'service') {
                if (selectedMenuId === undefined || selectedTopicId === undefined) {
                    console.log("No menu or topic selected")
                    return;
                }
                detachServiceFromTopic(projectId, selectedMenuId, selectedTopicId, item.id).then((serviceList: CbService[]) => {
                    serviceList.sort(sortServiceList);
                    setServiceList(prevState => serviceList);
                })
            }
        }

        if (mode === 'save') {
            if (item.type === 'service') {

                if (selectedMenuId === undefined || selectedTopicId === undefined) {
                    console.log("No menu or topic selected")
                    return;
                }
                const service: CbServiceOut = {
                    title: item.title,
                    description: item.description,
                    sequence: item.sequence,
                    language_code: item.language,
                    action_end: item.actionEnd,
                    action_end_type: item.actionEndType,
                    action_start: item.actionStart,
                    action_start_type: item.actionStartType
                }

                setIsProcessing(true);
                console.log("Updating service..." + item.id)
                updateService(projectId, selectedMenuId, selectedTopicId, item.id, service).then((serviceList: CbService[]) => {
                    serviceList.sort(sortServiceList);
                    setServiceList(prevState => serviceList);
                    setIsProcessing(false);
                })

            } else if (item.type === 'topic') {

                if (selectedMenuId === undefined) {
                    console.log("No menu selected")
                    return;
                }
                const topic: CbTopicOut = {
                    title: item.title,
                    description: item.description,
                    sequence: item.sequence,
                    language_code: item.language
                }

                console.log("Updating topic..." + item.id)
                updateTopic(projectId, selectedMenuId, item.id, topic).then((_topicList: CbTopic[]) => {
                    _topicList.sort(sortTopicList);
                    setTopicList(prevState => _topicList);
                })

            } else if (item.type === 'menu') {

                const menu: CbMenuOut = {
                    title: item.title,
                    description: item.description,
                    sequence: item.sequence,
                    language_code: item.language
                }

                updateMenu(projectId, item.id, menu).then((menuList: CbMenu[]) => {
                    setMenuList(prevState => menuList);
                })
            }
        }
    }

    // CHANGE TOPIC (ACCORDION) - CLOSE OTHER's BEFORE OPENING SELECTED ONE
    const handleChange =
        (panel: string, topic_uuid: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
            setSelectedTopicId(prevState => topic_uuid);
        };


    // JSX.element
    return (
        <>
            {/* Show available menus and buttons to create */}
            <Grid container marginTop={5} direction={"row"}>
                <Grid container direction="row" alignItems="center" paddingBottom={2} borderBottom={1}>

                    {/* Header with available menus and create buttons */}
                    <Grid item>
                        <Typography variant="h6" sx={{fontWeight: "400"}}>
                            Menu
                        </Typography>
                    </Grid>
                    <Grid item marginLeft={2}>
                        {isLoaded && (
                            <Select
                                labelId="select_menu"
                                size="small"
                                variant="outlined"
                                value={selectedMenuId}
                                id="select_menu"
                                label="Language"
                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    if (selectedValue.trim() !== '') {
                                        setSelectedMenuId(selectedValue);
                                    }
                                }}
                            >
                                {menuList.map((menuItem, index) => (
                                    <MenuItem key={index} value={menuItem.uuid}>{menuItem.title}</MenuItem>
                                ))}
                            </Select>
                        )}
                    </Grid>

                    <Grid item xs marginLeft="auto" textAlign="end">
                        <ButtonGroup>
                            <Button variant="contained" color={"success"} sx={{backgroundColor: colorSchema.color1}}
                                    onClick={() => handleCreateRequest("menu")}>Create
                                Menu</Button>
                            <Button variant="contained" color={"success"} sx={{backgroundColor: colorSchema.color1}}
                                    onClick={() => handleCreateRequest("topic")}>Create
                                Topic</Button>
                            <Button variant="contained" color={"success"} sx={{backgroundColor: colorSchema.color1}}
                                    onClick={() => handleCreateRequest("service")}>Create
                                Service</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>

            {/* Show selected menu with topics and services */}
            <Grid container marginTop={5} direction="row">
                <Grid item xs={4}>
                    {isLoaded && (
                        <>
                            {topicList?.map((topic, index) => (
                                <Accordion
                                    key={"topic_" + index}
                                    expanded={expanded === 'panel' + index}
                                    sx={{
                                        boxShadow: "none",
                                        borderWidth: "1px",
                                        borderStyle: "groove",
                                        borderColor: colorSchema.primary
                                    }}
                                    onChange={handleChange('panel' + index, topic.uuid)}
                                >
                                    <AccordionSummary
                                        aria-controls={"panelbh-content-" + index}
                                        id={"panelbh-" + index}
                                        sx={{backgroundColor: colorSchema.color1, color: "white"}}
                                        onClick={() => {
                                            showTopicDetails(topic.uuid);
                                            clearActions();
                                        }}
                                    >
                                        <Grid container direction={"column"}>
                                            <Typography
                                                variant={"body1"}
                                                fontWeight={selectedTopicId === topic.uuid ? "bold" : "normal"}
                                            >{topic.title} </Typography>
                                            {/*<Typography>*/}
                                            {/*    {topic.description}*/}
                                            {/*</Typography>*/}
                                        </Grid>

                                        {/* ======= TOPIC option menu ======== */}
                                        <MoreVertOutlinedIcon onClick={(event) => {
                                            setSelectedTopicId(topic.uuid);
                                            handleTopicMenuClicked(event);
                                        }}/>
                                    </AccordionSummary>

                                    {/* =============== Topics ======================= */}
                                    <AccordionDetails sx={{backgroundColor: "white"}}
                                    >
                                        {serviceList?.map((service, index) => (
                                            <Grid item key={"topic_" + index}>

                                                <Typography variant={"body1"}
                                                            color={selectedServiceId === service.uuid ? colorSchema.color2 : colorSchema.gray2}
                                                            fontWeight={selectedServiceId === service.uuid ? "bold" : "normal"}
                                                            textAlign={"justify"}
                                                            paddingBottom={1}
                                                            onClick={() => {
                                                                showServiceDetails(service.uuid)
                                                            }}>
                                                    {service.title}
                                                </Typography>
                                                {/*<Typography color={colorSchema.color1}>*/}
                                                {/*    {service.description}*/}
                                                {/*</Typography>*/}
                                                <Divider/>
                                            </Grid>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleTopicMenuCancel}
                    >
                        <MenuItem onClick={handleAssignServiceAction}>Assign Service</MenuItem>
                    </Menu>
                </Grid>

                {/* ===========-------- SERVICE DETAILS  ---------============ */}
                {isLoaded && (
                    <ManageItem
                        mode={'edit'}
                        isProcessing={isProcessing}
                        item={selectedItem}
                        message={""}
                        onSubmit={processManageItem}
                        onCancel={() => {
                        }}
                    />
                )}

                {/* -------- CREATE ITEM VIEW --------- */}
                {showCreateView && (
                    <Grid item>
                        <CreateItem
                            type={createMode}
                            message={""}
                            onSubmit={processCreateItem}
                            onCancel={() => {
                                setShowCreateView(false)
                            }}
                            open={showCreateView}
                        />
                    </Grid>
                )}

                {/* -------- ASSIGN SERVICE VIEW --------- */}
                {showServiceList && (
                    <Grid item>
                        <ServiceList
                            mode={serviceListMode}
                            serviceList={detachedServiceList || []}
                            onRequest={handleServiceSelected}
                            onCancel={() => {
                                setShowServiceList(false)
                            }}
                            open={true}
                        />
                    </Grid>
                )}

                {/* -------- CONFIRM DELETE --------- */}
                {showConfirmDelete && (
                    <AlertDialog open={true} title={`Delete - ${selectedItem.title} `}
                                 message={`Are you sure you want to delete the selected ${selectedItem.type}? Please confirm your decision`}
                                 onSubmit={() => {
                                 }}
                    />
                )}
            </Grid>
        </>
    );
}