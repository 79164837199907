import axios from 'axios';

// let service_url = "https://lexicaliaservices.azurewebsites.net";
// service_url = "http://localhost:8000";

import config from '../config.json';
const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

export type Organisation = {
    uuid: string;
    company_name: string;
    vat_number: string;
    address: string;
    zip_code: string;
    location: string;
    country: string;
    description: string;
}

export type Application = {
    uuid: string;
    application_name: string;
    application_domain: string;
    application_code: string;
    description: string;
}

export type Project = {
    uuid: string;
    project_name: string;
    description: string;
    props: string;
}

export function getOrganisations(): Promise<Organisation[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/main/organisation/all`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as Organisation[];
        })
        .catch(error => {
            console.error(error);
            return [] as Organisation[];
        });
}

export function getProjects(organisationId?: string, applicationId?: string): Promise<Project[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    let url = service_url + `/main/organisation/${organisationId}/application/${applicationId}/project/all`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as Project[];
        })
        .catch(error => {
            console.error(error);
            return [] as Project[];
        });
}

export function getApplications(organisationId?: string): Promise<Application[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    let url = service_url + `/main/application/all`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as Application[];
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
}

