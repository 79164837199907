import React, {useEffect, useState} from "react";
import {
    Application,
    getApplications,
    getOrganisations,
    getProjects,
    Organisation,
    Project
} from "../backend/core.services";
import {Link, List, ListItem, Typography} from "@mui/material";
import {SelectWithData, ValueLabel} from "./general/Site.snippets";
import {
    applicationList,
    currentOrganization,
    setApplicationList,
    setCurrentOrganization
} from "./managers/generalManager";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import {styled} from "@mui/material/styles";
import {colorSchema} from "../data/theme";

interface showOrganisationsProps {
    onChange: (organisationId: string) => void;
}

// ShowOrganisations
// ---------------------------------------------------------------------------------------------------------------
export function ShowOrganisations(props: showOrganisationsProps) {
    const [organisationList, setOrganisationList] = useState<Organisation[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>(0);

    useEffect(() => {
        getOrganisations().then((list) => {
            setOrganisationList(list);
            setIsLoaded(true);
            if (list.length > 0) {
                // search current organisation in the list retrieved from the backend
                for (let i = 0; i < list.length; i++) {
                    if (list[i].uuid === currentOrganization) {
                        setSelected(i);
                        break;
                    }
                }
            }
        })
    }, []);

    // change organisation
    function handleSelectOrganisation(item: ValueLabel) {
        const foundIndex = organisationList.findIndex((organisation) => organisation.uuid === item.value);
        if (foundIndex !== -1) {
            setCurrentOrganization(item.value);
            localStorage.setItem('BoostrFoundation_SelectedOrganisation', JSON.stringify(organisationList[foundIndex]));
            props.onChange(item.value);
        }
    }

    return (
        <Box sx={{textAlign: "center"}}>
            {isLoaded && (
                <SelectWithData
                    id={"SelectOrganisations"}
                    sx={{marginTop: '15px', minWidth: '200px'}}
                    label={""}
                    isOpen={true}
                    data={organisationList.map((organisation) => ({
                        value: organisation.uuid,
                        label: organisation.company_name,
                    }))}
                    selected={selected}
                    onSelect={handleSelectOrganisation}
                />
            )}
        </Box>
    );

    // return (
    //     <>
    //         {organisationList.map((organisation, index) => (
    //             <ListItem
    //                 key={organisation.uuid}
    //                 nested
    //                 sx={{my: 1}}
    //                 startAction={
    //                     <IconButton
    //                         variant="plain"
    //                         size="sm"
    //                         color="neutral"
    //                         onClick={() => setOpen(!open)}
    //                     >
    //                         <KeyboardArrowDown sx={{transform: open ? 'initial' : 'rotate(-90deg)'}}/>
    //                     </IconButton>
    //                 }
    //             >
    //                 <ListItemButton>
    //                     <Typography
    //                         level="inherit"
    //                         sx={{
    //                             fontWeight: open ? 'bold' : undefined,
    //                             color: open ? 'text.primary' : 'inherit',
    //                         }}
    //                     >
    //                         {organisation.company_name}
    //                     </Typography>
    //                 </ListItemButton>
    //                 {open && <ShowApplications organisationId={organisation.uuid} id={index}/>}
    //             </ListItem>
    //         ))}
    //     </>
    // );
}


interface ShowApplicationsProps {
    organizationId: string;
}

// ShowApplications
// ---------------------------------------------------------------------------------------------------------------
function ShowApplications(props: ShowApplicationsProps): JSX.Element {
    const [applicationListLoaded, setApplicationListLoaded] = useState<Application[]>([])
    const [organizationId, setOrganizationId] = useState<string>(props.organizationId);
    const [open, isOpen] = useState(false);

    // Handle input parameter change
    useEffect(() => {
        setOrganizationId(props.organizationId)
    }, [props.organizationId]);

    useEffect(() => {
        if (applicationList.length === 0) {
            getApplications(organizationId).then((list) => {
                setApplicationList(list);
                setApplicationListLoaded(list);
            });
        }
    }, [applicationListLoaded]);


    return (
        <List key={"appList_" + props.organizationId}>
            <ListItem key={props.organizationId}>
                <StyledLink
                    href={`/BoostrManagement?organizationId=${props.organizationId}`}
                    underline={"none"} variant={"body1"} color={"inherit"}>
                    <img src="/zaia_admin.png" alt="Admin"
                         style={{maxWidth: '90%', height: 'auto'}}/>
                    <Typography variant={"h5"} className="hover-shadow" sx={{textAlign: 'center', color: colorSchema.color1}}>Administration</Typography>
                </StyledLink>
            </ListItem>
            {applicationList.map((application: Application) => (
                <ListItem key={application.uuid}>
                    <ShowProjects
                        organisationId={props.organizationId}
                        applicationId={application.uuid}
                        applicationCode={application.application_code}
                        key={application.uuid}
                    />
                </ListItem>
            ))}
        </List>
    );
}

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    transition: 'filter 0.3s ease-in-out',
  },
  '&:hover img': {
    filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5))',
  },
  '& img:hover + .hover-shadow': {
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
});


interface ShowProjectsProps {
    organisationId: string;
    applicationCode: string;
    applicationId: string;
}

// ShowProject
// ---------------------------------------------------------------------------------------------------------------
function ShowProjects(props: ShowProjectsProps) {
    const [projectList, setProjectList] = useState<Project[]>([]);

    function handleButtonClick(projectId: string, projectName: string) {
    }

    useEffect(() => {
        getProjects(props.organisationId, props.applicationId).then((list) => setProjectList(list));
    }, []);

    return (
        <Box>
            <List key={props.applicationId}>
                {projectList.map((project) => (
                    <ListItem key={project.uuid}>
                        {props.applicationCode === "ChatBoostr" && (
                            <StyledLink
                                href={`/${props.applicationCode}?project_name=${project.project_name}&uuid=${project.uuid}`}
                                underline={"none"} variant={"body1"} color={"inherit"}>
                                <img src="/zaia_templates.png" alt="ZAIA Templates"
                                     style={{maxWidth: '100%', height: 'auto'}}/>
                                <Typography variant={"h5"} className="hover-shadow" sx={{textAlign: 'center', color: colorSchema.color1}}>Templates</Typography>
                            </StyledLink>

                        )}
                        {props.applicationCode === "ExtData" && (
                            <StyledLink
                                href={`/DataBoostr?project_name=${project.project_name}&uuid=${project.uuid}`}
                                underline={"none"} variant={"body1"} color={"inherit"}>
                                <img src="/zaia_knowledge_base.png" alt="Knowledge Base"
                                     style={{maxWidth: '100%', height: 'auto'}}/>
                                <Typography variant={"h5"} className="hover-shadow" sx={{textAlign: 'center', color: colorSchema.color1}}>Knowledge</Typography>
                            </StyledLink>
                        )}
                        {props.applicationCode === "Recruitment" && (
                            <StyledLink
                                href={`/TalentBoostr?project_name=${project.project_name}&uuid=${project.uuid}`}
                                underline={"none"} variant={"body1"} color={"inherit"}>
                                <img src="/zaia_talent.png" alt="Talent"
                                     style={{maxWidth: '100%', height: 'auto'}}/>
                                <Typography variant={"h5"} className="hover-shadow" sx={{textAlign: 'center', color: colorSchema.color1}}>Talent</Typography>
                            </StyledLink>
                        )}
                    </ListItem>
                ))}
            </List>
        </Box>

    );
}

export interface AvailableProjectsProps {
    organizationId: string;
}

// Component to show all available project based on the given user.
// Starting from the organisations, over the applications with the project selectable.
// ---------------------------------------------------------------------------------------------------------------
export const AvailableProjects = (props: AvailableProjectsProps) => {
    const [open, setOpen] = useState(false);
    const [currentOrganization, setCurrentOrganization] = useState<string>(props.organizationId);

    function handleChangeOrganisation(organizationId: string) {
        setCurrentOrganization(organizationId);
    }

    return (
        <Box>
            <ShowOrganisations onChange={handleChangeOrganisation}/>
            <ShowApplications organizationId={currentOrganization}/>
        </Box>
    )
};

export default AvailableProjects;