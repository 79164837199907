import React, {useEffect, useState} from 'react';
import {Backdrop, CircularProgress, Container, Typography} from "@mui/material";
import {ExtDataRecordList} from "../components/extdata/ListExtDataRecords";
import {
    addExtDataFile,
    addExtDataRecord,
    deleteExtDataRecord,
    ExtDataRecordOut,
    updateExtDataRecord
} from "../backend/extdata.services";
import {useSearchParams} from "react-router-dom";
import {colorSchema} from "../data/theme";


interface DataBoostrPageProps {
    message: string;
}

const DataBoostrPage = (props: DataBoostrPageProps) => {

    const [projectName, setProjectName] = useState<string>('');
    const [projectUUID, setProjectUUID] = useState<string>('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isProcessed, setIsProcessed] = useState(false);
    const [searchParams] = useSearchParams();
    const [currentNamespace, setCurrentNamespace] = useState<string>("");

    useEffect(() => {
        console.log("Loading ExtData")
        setProjectName(searchParams.get('project_name') || "");
        setProjectUUID(searchParams.get('uuid') || "");
        setIsLoaded(true);
    }, []);

    function handleRecordAdded(data: ExtDataRecordOut, file: File | null | undefined, addToIndex: boolean) {
        data.uuid = "";
        data.keywords = data.metadata_extra;
        setCurrentNamespace(data.namespace);

        // setIsLoaded(false)
        if (file) {
            console.log("File to upload")
            setIsProcessed(true);
            addExtDataFile(projectUUID, data, file, addToIndex).then(() => {
                setIsLoaded(false);
                setTimeout(() => {
                    setIsLoaded(true);
                    setIsProcessed(false);
                }, 250);
            })

        } else {
            console.log("No file to upload")
            addExtDataRecord(projectUUID, data, addToIndex).then(() => {
                setIsLoaded(false);
                setTimeout(() => {
                    setIsLoaded(true);
                    setIsProcessed(false);
                }, 250);

            })
        }
    }

    function handleUrlImport(namespace: string, urlList: string[], addToIndex: boolean  ) {
        urlList.forEach(url => {
            const data: ExtDataRecordOut = {
                project: projectUUID,
                uuid: "",
                title: url,
                original: "",
                processed: "",
                keywords: "",
                namespace: namespace,
                metadata_resource: url,
                metadata_type: "",
                metadata_language: "",
                metadata_extra: "",
            }

            setIsProcessed(true)
            addExtDataRecord(projectUUID, data, addToIndex).then(() => {
                setIsLoaded(false);
                setTimeout(() => {
                    setIsLoaded(true);
                    setIsProcessed(false);
                }, 250);
            })
        })
    }

    function handleRecordUpdate(data: ExtDataRecordOut, file: File | null | undefined) {
        setCurrentNamespace(data.namespace);
        data.keywords = data.metadata_extra;
        setIsProcessed(true);
        updateExtDataRecord(projectUUID, data.uuid || "", data).then(() => {
            setIsLoaded(true);
            setIsProcessed(false);
        })
    }


    function handleRecordDeleted(record_uuid: string, namespace: string) {
        setCurrentNamespace(namespace);
        setIsLoaded(false)
        setIsProcessed(true)
        deleteExtDataRecord(projectUUID, record_uuid).then(() => {
            setIsLoaded(false);
            setTimeout(() => {
                setIsLoaded(true);
                setIsProcessed(false);
            }, 250);

        })
        console.log(`Record to delete : ${record_uuid}`);
    }

    return (
        <Container maxWidth="xl" sx={{marginTop: '30px'}}>
            <Typography variant={"h3"} sx={{
                color: colorSchema.primary,
                textAlign: "center",
                marginTop: -2,
                marginBottom: -2
            }}>Knowledge Base</Typography>
            {isLoaded && (
                <ExtDataRecordList projectId={projectUUID} onRecordAdded={handleRecordAdded} namespace={currentNamespace}
                                   onRecordDeleted={handleRecordDeleted} onRecordUpdate={handleRecordUpdate}
                                   onUrlImport={handleUrlImport}/>
            )}
            {isProcessed && (
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={isProcessed}
                    onClick={() => setIsProcessed(false)}
                >
                    <Typography variant={"h3"}>Processing</Typography>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            )}
        </Container>
    );
};

export default DataBoostrPage;