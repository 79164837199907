import axios from 'axios';

import config from '../config.json';

const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;
console.log(`Server to connect : ${service_url}`);

export type ExtDataRecordMeta = {
    resource: string;
    type: string;
    language: string;
    extra: string;
}

export type ExtDataRecord = {
    uuid: string;
    title: string;
    original: string;
    processed: string;
    project: string;
    namespace: string;
    meta_data: ExtDataRecordMeta;
    // metadata_resource: string;
    // metadata_type: string;
    // metadata_language: string;
    // metadata_extra: string;
    chunks_original: number;
    chunks_processed: number;
}

export type ExtDataPage = {
    uuid: string;
    filename: string;
    page: number;
    original: string;
    processed: string;
    metadata: string;
}

export type ExtDataRecordOut = {
    uuid: string;
    title: string;
    original: string;
    processed: string;
    project: string;
    namespace: string;
    keywords: string;
    metadata_resource: string;
    metadata_type: string;
    metadata_language: string;
    metadata_extra: string;
}

export type QueryResult = {
    uuid: string;
    title: string;
    data: string;
    score: number;
    props: string;
}


// Retrieve all ExtData entries pertaining to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function getExtDataRecordList(projectId: string, namespace: string): Promise<ExtDataRecord[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};

    let url = service_url + `/modules/extdata/${projectId}/record/${namespace}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as ExtDataRecord[];
        })
        .catch(error => {
            console.error(error);
            return [] as ExtDataRecord[];
        });
}

// Add new ExtData record to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function addExtDataRecord(projectId: string, data: ExtDataRecordOut, addToIndex: boolean): any {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/extdata/${projectId}/record?add_to_knowledge_base=${addToIndex}`;

    return axios.post(url, data, {headers})
        .then(response => {
            console.log(response.data);
            return response.data;
        })
        .catch(error => {
            console.error(error);
            return "";
        });
}

export function addExtDataFile(projectId: string, data: ExtDataRecordOut, file?: File | null, addToIndex: boolean = false): any {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };

     const url = service_url + `/modules/extdata/${projectId}/file?title=${encodeURIComponent(data.title)}` +
        `&namespace=${encodeURIComponent(data.namespace)}` +
        `&metadata_type=${encodeURIComponent(data.metadata_type)}` +
        `&metadata_resource=${encodeURIComponent(data.metadata_resource)}` +
        `&metadata_language=${encodeURIComponent(data.metadata_language)}` +
        `&metadata_extra=${encodeURIComponent(data.metadata_extra)}` +
        `&keywords=${encodeURIComponent(data.metadata_extra)}`+
        `&add_to_knowledge_base=${addToIndex}`;


    // const url = service_url + `/modules/extdata/${projectId}/file?title=${data.title}&namespace=${data.namespace}` +
    //     `&metadata_type=${data.metadata_type}&metadata_resource=${data.metadata_resource}` +
    //     `&metadata_language=${data.metadata_language}&metadata_extra=${data.metadata_extra}` +
    //     `&keywords=${data.metadata_extra}`;

    const formData = new FormData();

    if (file) {
        formData.append('file', file, file.name);
    }

    return axios.post(url, formData, {headers})
        .then(response => {
            console.log(response.data);
            return response.data;
        })
        .catch(error => {
            console.error(error);
            return "";
        });
}


// Update ExtData record to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function updateExtDataRecord(projectId: string, recordId: string, data: ExtDataRecordOut): any {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json' // add Content-Type header
    };
    const url = service_url + `/modules/extdata/${projectId}/record/${recordId}`;

    return axios.post(url, data, {headers}) // pass data and headers to post method
        .then(response => {
            console.log(response.data);
            return response.data;
        })
        .catch(error => {
            console.error(error);
            return "";
        });
}

// Delete ExtData record to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function deleteExtDataRecord(projectId: string, recordId: string): any {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json' // add Content-Type header
    };
    const url = service_url + `/modules/extdata/${projectId}/record/${recordId}`;

    return axios.delete(url, {headers}) // pass data and headers to post method
        .then(response => {
            console.log(response.data);
            return response.data;
        })
        .catch(error => {
            console.error(error);
            return "";
        });
}

// Retrieve records from the project based on their embedding.
// ----------------------------------------------------------------------------------------------------------------
interface RunQueryData {
    query: string;
    score: number;
    max_results: number;
    mode: string;
    filter?: string;
    namespace: string;
}

export function runQuery(projectId: string, query: string, namespace: string, filter?: string): Promise<QueryResult[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/extdata/${projectId}/query`;

    const data: RunQueryData = {
        query: query, score: 70, max_results: 6, mode: 'all', filter: filter, namespace: namespace,
    }

    return axios.post(url, data, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as QueryResult[];
        })
        .catch(error => {
            console.error(error);
            return [] as QueryResult[];
        });
}


// Retrieve sitemap urls from a given url.
// ----------------------------------------------------------------------------------------------------------------
export function getSiteList(projectId: string, siteUrl: string): Promise<string[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/extdata/${projectId}/getSitemapUrls?url=${siteUrl}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as string[];
        })
        .catch(error => {
            console.error(error);
            return [] as string[];
        });
}

// Retrieve parameter value from the project.
// ----------------------------------------------------------------------------------------------------------------
export function getParameter(projectId: string, key: string, key_type: string): Promise<string> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/extdata/${projectId}/parameter?key=${key}&key_type=${key_type}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data;
        })
        .catch(error => {
            console.error(error);
            return "{}";
        });
}

export function AddUpdateParameter(projectId: string, key: string, key_type: string, value: any): Promise<void> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url =  service_url + `/modules/extdata/${projectId}/parameter`;
    const data = {
        key: key,
        key_type: key_type,
        value: value
    };

    return axios.post(url, data, { headers })
        .then(response => {
            console.log('Response:', response);
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

// ----------------------------------------------------------------------------------------------------------------
export async function getExtPageCount(projectId: string, filename: string): Promise<number> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
    };
    const url = `${service_url}/modules/extdata/${projectId}/getPageCount?filename=${filename}`;

    try {
        const response = await axios.get(url, {headers});
        return response.data.pages;
    } catch (error) {
        console.error(error);
        return 0;
    }
}

export async function getExtPageData(projectId: string, filename: string, page: number): Promise<ExtDataPage | undefined> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
    };
    const url = `${service_url}/modules/extdata/${projectId}/page?filename=${filename}&page_number=${page}`;

    try {
        const response = await axios.get<ExtDataPage>(url, {headers});
        return response.data;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

interface ExtDataPageImage {
    page: number;
    image: Uint8Array;
}

export async function getExtPageImage(
    projectId: string,
    filename: string,
    page: number
): Promise<ExtDataPageImage | undefined> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
    };

    const url = `${service_url}/modules/extdata/${projectId}/getImage/${page}?filename=${filename}`;

    try {
        const response = await axios.get<ExtDataPageImage>(url, {headers});
        return response.data;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}


// ----------------------------------------------------------------------------------------------------------------
interface GPTRequest {
    data: string;
    action: string;
    language: string;
    prompt: string;
}

export async function runGPTRequest(
    projectId: string,
    action: string,
    language: string,
    input_data: string,
    prompt: string,
    onDataReceived: (chunk: string) => void
): Promise<void> {
    const accessToken = localStorage.getItem("BoostrFoundation_AccessToken");
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "text/event-stream",
        "Content-Type": "application/json",
    };

    const data: GPTRequest = {
        data: input_data,
        action: action,
        language: language,
        prompt: prompt,
    };
    const url = `${service_url}/modules/extdata/${projectId}/gpt`;

    const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(
            `Failed to retrieve data from event stream: ${response.status} ${response.statusText}`
        );
    }

    if (!response.body) {
        throw new Error("No response body found.");
    }

    const reader = response.body.getReader();
    let buffer = "";
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }

        buffer += new TextDecoder().decode(value);
        const markerRegex = /\b/g; // Updated regex to match word boundaries
        const words = buffer.split(markerRegex); // Renamed 'chunks' to 'words'

        for (let i = 0; i < words.length - 1; i++) {
            onDataReceived(words[i]);
        }

        buffer = words[words.length - 1];
    }
}


