import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersistentDrawerLeft from "./NavDrawerComponent";
import {useEffect, useState} from "react";
import {CssBaseline} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {styled, useTheme} from '@mui/material/styles';
import {colorSchema} from "../../data/theme";
import {applicationList} from "../managers/generalManager";

export interface NavBarProps {
    isAuthenticated: boolean;
    onLogInRequested?: () => void;
    onLogOutRequested?: () => void;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


export const NavBar: React.FC<NavBarProps> = ({isAuthenticated, onLogOutRequested, onLogInRequested}) => {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        console.log("Loading NavBar...");
        console.log(applicationList);
    }, [isAuthenticated])


    const LogInSection = () => {
        return (
            <>
                {isAuthenticated ? (
                    <Button color="inherit" onClick={onLogOutRequested}>Logout</Button>
                ) : (
                    <Button color="inherit" onClick={onLogInRequested}>Login</Button>
                )}
            </>
        )
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="sticky" open={open}>
                <Toolbar sx={{display: 'flex', justifyContent: 'flex-start', backgroundColor: colorSchema.color1}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1, color: 'white', textAlign: 'left'}}>
                        ZAIA Management Console.
                    </Typography>
                    <Box sx={{justifyContent: 'flex-end'}}>
                        <LogInSection/>
                    </Box>
                </Toolbar>
            </AppBar>
            <PersistentDrawerLeft open={open} onClose={handleDrawerClose} />
        </Box>
    )
}

