import React, {useEffect, useState} from 'react';
import {Container, Typography} from "@mui/material";
import SpeechToText from "../components/speech/SpeechToText";
import {colorSchema} from "../data/theme";

interface AppProps {
    message: string;
}

export const MainPage = (props: AppProps) => {

    return (
        <Container maxWidth="xl">
            <img src="/boostr.png" alt="Boostr"
                 style={{maxWidth: '100%', height: 'auto', marginTop: '20px', marginBottom: '20px'}}/>
            <Typography variant="h2" style={{textAlign: 'center', color: colorSchema.color1}}>ZAIA Management Console</Typography>
            <Typography variant="h3" style={{textAlign: 'center', color: colorSchema.color2}}>Maximize your productivity
                by harnessing the power of generative AI.</Typography>

            {/*<h1>MAIN</h1>*/}
            {/*<SpeechToText></SpeechToText>*/}
        </Container>

    );
};

export default MainPage;
