import config from '../config.json';
import axios, {AxiosResponse} from "axios";


const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

export interface JobDescription {
    uuid: string,
    title: string,
    original: string,
    processed: string,
    status: string
}

export interface Candidate {
    uuid: string,
    title: string,
    original: string,
    processed: string,
    status: string
}

export interface addJobDescriptionData {
    title: string,
    original: string
}

export interface CandidateMatch {
    uuid: string;
    title: string;
    data: string;
    score: number;
    props: string;
}

export interface JobDescriptionMatch {
    uuid: string;
    title: string;
    data: string;
    score: number;
    props: string;
}


export interface SimpleResponse {
    success: boolean;
    message?: string;
}

interface GPTRequest {
    candidate_uuid: string;
    job_description_uuid: string;
    action: string;
    language: string;
    useOriginal:boolean;
    prompt: string;
}


// ===================================== PART 1 : JOB DESCRIPTIONS ============================================


// Add a new job description to the project
// ----------------------------------------------------------------------------------------------------------------
export async function addJobDescription(projectId: string, data: addJobDescriptionData): Promise<JobDescription | undefined> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/job_description/register`;

    return axios.post(url, data, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as JobDescription;
        })
        .catch(error => {
            console.error(error);
            return undefined;
        });
}

// Update given job description
// ----------------------------------------------------------------------------------------------------------------
export async function updateJobDescription(projectId: string, data: JobDescription): Promise<JobDescription | undefined> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/job_description/${data.uuid}/update`;

    return axios.post(url, data, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as JobDescription;
        })
        .catch(error => {
            console.error(error);
            return undefined;
        });
}


// Retrieve all job descriptions for the selected project.
// ----------------------------------------------------------------------------------------------------------------
export async function getJobDescriptionList(projectId: string, status: string = "done"): Promise<JobDescription[]> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/job_description/all?status=${status}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as JobDescription[];
        })
        .catch(error => {
            console.error(error);
            return [];
        });
}

// Retrieve all job descriptions for the selected project.
// ----------------------------------------------------------------------------------------------------------------
export async function getJobDescription(projectId: string, jobId: string): Promise<JobDescription | undefined> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/job_description/${jobId}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as JobDescription;
        })
        .catch(error => {
            console.error(error);
            return undefined;
        });
}


// Delete a specific job description for the selected project
// ----------------------------------------------------------------------------------------------------------------
export async function deleteJobDescription(projectId: string, jobId: string): Promise<boolean> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/job_description/${jobId}`;

    return axios.delete(url, {headers})
        .then(response => {
            console.log(response.data);
            return true;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}

// ===================================== PART 2 : CANDIDATES ============================================

// Register new Candidate
// ----------------------------------------------------------------------------------------------------------------
export async function registerCandidate(projectId: string, title: string, file: File): Promise<Candidate | undefined> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };
    const url = service_url + `/modules/recruitment/${projectId}/candidate/register?title=${title}`;

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file, file.name);

    return axios.post(url, formData, {headers})
        .then((response: AxiosResponse<Candidate>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return undefined
        })
}

// Update Candidate
// ----------------------------------------------------------------------------------------------------------------
export async function updateCandidate(projectId: string, data: Candidate): Promise<Candidate | undefined> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/candidate/${data.uuid}/update`;

    return axios.post(url, data, {headers})
        .then((response: AxiosResponse<Candidate>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return undefined
        })
}

// Retrieve all job descriptions for the selected project.
// ----------------------------------------------------------------------------------------------------------------
export async function getCandidateList(projectId: string, status: string = "all"): Promise<Candidate[]> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/candidate/all?status=${status}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as Candidate[];
        })
        .catch(error => {
            console.error(error);
            return [];
        });
}

// Retrieve all job descriptions for the selected project.
// ----------------------------------------------------------------------------------------------------------------
export async function getCandidate(projectId: string, candidateId: string): Promise<Candidate | undefined> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/recruitment/${projectId}/candidate/${candidateId}`;

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as Candidate;
        })
        .catch(error => {
            console.error(error);
            return undefined;
        });
}


// Delete an existing candidate
// ----------------------------------------------------------------------------------------------------------------
export async function deleteCandidate(projectId: string, candidateId: string): Promise<SimpleResponse> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
    };
    const url = service_url + `/modules/recruitment/${projectId}/candidate/${candidateId}`;

    return axios.delete(url, {headers})
        .then((response: AxiosResponse<SimpleResponse>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return {success: false, message: 'An error occurred while deleting candidate.'};
        });
}

// ===================================== PART 3 : MATCHING ============================================


// Find matching candidates
// ----------------------------------------------------------------------------------------------------------------
export async function findCandidates(projectId: string, jobId: string, score: number, maxResults: number, mode: string): Promise<CandidateMatch[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/recruitment/${projectId}/job_descriptions/${jobId}/find_candidates`;

    const requestData = {
        score,
        max_results: maxResults,
        mode
    };

    return axios.post(url, requestData, {headers})
        .then((response: AxiosResponse<CandidateMatch[]>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

// Find matching candidates
// ----------------------------------------------------------------------------------------------------------------
export async function findJobs(projectId: string, candidateId: string, score: number, maxResults: number, mode: string): Promise<JobDescriptionMatch[]> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/recruitment/${projectId}/candidate/${candidateId}/find_job_descriptions`;

    const requestData = {
        score,
        max_results: maxResults,
        mode
    };

    return axios.post(url, requestData, {headers})
        .then((response: AxiosResponse<JobDescriptionMatch[]>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

// Find matching candidates
// ----------------------------------------------------------------------------------------------------------------
export async function QueryForCandidates(projectId: string, query: string, score: number, maxResults: number): Promise<CandidateMatch[]> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/recruitment/${projectId}/query`;

    const requestData = {
        query,
        score,
        max_results: maxResults,
        mode: 'candidate',
        prompt: ''
    };

    return axios.post(url, requestData, {headers})
        .then((response: AxiosResponse<CandidateMatch[]>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}

// Find matching candidates
// ----------------------------------------------------------------------------------------------------------------
export async function QueryForJobDescriptions(projectId: string, query: string, score: number, maxResults: number): Promise<JobDescriptionMatch[]> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/recruitment/${projectId}/query`;

    const requestData = {
        query,
        score,
        max_results: maxResults,
        mode: 'job_description',
        prompt: ''
    };

    return axios.post(url, requestData, {headers})
        .then((response: AxiosResponse<JobDescriptionMatch[]>) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        });
}



// Retrieve streaming data
// ----------------------------------------------------------------------------------------------------------------
export async function runActionRequest(projectId: string, action: string, language: string, candidateId: string, jobId: string, onDataReceived: (chunk: string) => void): Promise<void> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'text/event-stream'
    };

    const url = service_url + `/modules/recruitment/${projectId}/candidate/${candidateId}/action/${action}?job_description_uuid=${jobId}&language=${language}`;

    const response = await fetch(url, {headers: headers});

    if (!response.ok) {
        throw new Error(`Failed to retrieve data from event stream: ${response.status} ${response.statusText}`);
    }

    // @ts-ignore
    const reader = response.body.getReader();
    let buffer = '';
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }

        // Convert the received data to a string and split it into chunks based on the newline character
        buffer += new TextDecoder().decode(value);
        const chunks = buffer.split('\n');

        // Call the callback function for each chunk
        for (let i = 0; i < chunks.length - 1; i++) {
            onDataReceived(chunks[i]);
        }

        // Keep the last incomplete chunk in the buffer
        buffer = chunks[chunks.length - 1];
    }
}





// Retrieve streaming data
// ----------------------------------------------------------------------------------------------------------------
export async function runGPTRequest(projectId: string, action: string, language: string, candidateId: string, jobId: string, prompt: string, useOriginal: boolean, onDataReceived: (chunk: string) => void): Promise<void> {

    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'text/event-stream',
        'Content-Type': 'application/json' // Add this header for POST requests
    };

    const data: GPTRequest = { candidate_uuid: candidateId, job_description_uuid: jobId, prompt: prompt, action: action, language: language, useOriginal: useOriginal }

    const url = service_url + `/modules/recruitment/${projectId}/gpt`;

    const response = await fetch(url, {
        method: 'POST', // Use the POST method
        headers: headers,
        body: JSON.stringify(data) // Convert the request data to JSON and add it to the request body
    });

    if (!response.ok) {
        throw new Error(`Failed to retrieve data from event stream: ${response.status} ${response.statusText}`);
    }

    // @ts-ignore
    const reader = response.body.getReader();
    let buffer = '';
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }

        // Convert the received data to a string and split it into chunks based on the newline character
        buffer += new TextDecoder().decode(value);
        const chunks = buffer.split('\n');

        // Call the callback function for each chunk
        for (let i = 0; i < chunks.length - 1; i++) {
            onDataReceived(chunks[i]);
        }

        // Keep the last incomplete chunk in the buffer
        buffer = chunks[chunks.length - 1];
    }
}
















