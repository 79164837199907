import React, { useState, ChangeEvent } from 'react';
import { Button, TextField } from '@mui/material';

const ImageUpload: React.FC = () => {
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {

    console.log("handleImageChange");
    const file = e.target.files ? e.target.files[0] : null;
    if (file && file.type.startsWith('image/')) {
      setImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImageUrl(null);
    }
  };

  return (
    <div>
      <TextField
        type="file"
        onChange={handleImageChange}
        variant="outlined"
        margin="normal"
        fullWidth
      />
      {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto' }} />}
    </div>
  );
};

export default ImageUpload;
