import axios from 'axios';

import config from '../config.json';

const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;


export type Item = {
    uuid: string;
    type: string;
    title_uuid: string;
    title_text: string;
    description_uuid: string;
    description_text: string;
    action_start_uuid: string;
    action_start_type: string;
    action_start: string;
    action_end_uuid: string;
    action_end_type: string;
    action_end: string;
    default_language: string;
    sequence: number;
    child_list: Item[];
};

export const emptyItem: Item = {
    uuid: '',
    type: '',
    title_uuid: '',
    title_text: '',
    description_uuid: '',
    description_text: '',
    action_start_uuid: '',
    action_start_type: '',
    action_start: '',
    action_end_uuid: '',
    action_end_type: '',
    action_end: '',
    default_language: '',
    sequence: 0,
    child_list: [],
};


export type Node = {
    uuid?: string;
    value: string;
    type: string;
    language: string;
    sequence: number;
}

export type BaseItem = {
    title: string;
    description: string;
    sequence: number;
}


export type CbMenu = {
    uuid: string;
    title: string;
    description: string;
    language_code: string;
    is_default: boolean;
    is_active: boolean;
    sequence: number;
}

export type CbMenuOut = {
    title: string;
    description: string;
    language_code: string;
    sequence: number;
}

export type CbTopic = {
    uuid: string;
    title: string;
    description: string;
    language_code: string;
    is_default: boolean;
    is_detached: boolean;
    sequence: number;
}

export type CbTopicOut = {
    title: string;
    description: string;
    language_code: string;
    sequence: number;
}

export type CbService = {
    uuid: string;
    title: string;
    action_start: string;
    action_start_type: string;
    action_end: string;
    action_end_type: string;
    description: string;
    language_code: string;
    is_default: boolean;
    is_detached: boolean;
    sequence: number;
}

export type CbServiceOut = {
    title: string;
    description: string;
    action_start: string;
    action_start_type: string;
    action_end: string;
    action_end_type: string;
    language_code: string;
    sequence: number;
}


export function getMenus(projectId: string): Promise<CbMenu[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbMenu[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbMenu[];
        });
}

export function getTopics(projectId: string, menuId: string ): Promise<CbTopic[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbTopic[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbTopic[];
        });
}

export function getServices(projectId: string, menuId: string, topicId: string ): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}/service`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbService[];
        });
}

export function getDetachedServices(projectId: string): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/detached/service`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbService[];
        });
}

export function getDetachedTopics(projectId: string): Promise<CbTopic[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/detached/topic`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbTopic[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbTopic[];
        });
}


// NEW : Create menu
export function createMenu(projectId: string, menu: CbMenuOut): Promise<CbMenu[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu`

    return axios.post(url, menu, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbMenu[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbMenu[];
        });
}

// NEW : Create topic
export function createTopic(projectId: string, menuId: string, topic: CbTopicOut): Promise<CbTopic[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic`

    return axios.post(url, topic, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbTopic[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbTopic[];
        });
}

// NEW : Create service
export function createService(projectId: string, menuId: string, topicId: string, service: CbServiceOut): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}/service`

    return axios.post(url, service, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbService[];
        });
}

// NEW : Update menu
export function updateMenu(projectId: string, menuId:string, menu: CbMenuOut): Promise<CbMenu[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}`

    return axios.put(url, menu, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbMenu[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbMenu[];
        });
}

// NEW : Update topic
export function updateTopic(projectId: string, menuId: string, topicId: string, topic: CbTopicOut): Promise<CbTopic[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}`

    return axios.put(url, topic, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbTopic[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbTopic[];
        });
}

// NEW : Update service
export function updateService(projectId: string, menuId: string, topicId: string, serviceId: string, service: CbServiceOut): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}/service/${serviceId}`

    return axios.put(url, service, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbService[];
        });
}

// NEW : Delete menu
export function deleteMenu(projectId: string, menuId:string): Promise<CbMenu[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}`

    return axios.put(url, "", {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbMenu[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbMenu[];
        });
}

// NEW : Delete topic
export function deleteTopic(projectId: string, menuId: string, topicId: string): Promise<CbTopic[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}`

    return axios.delete(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbTopic[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbTopic[];
        });
}

// NEW : Delete service
export function deleteService(projectId: string, menuId: string, topicId: string, serviceId: string): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}/service/${serviceId}`

    return axios.delete(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return [] as CbService[];
        });
}

// NEW : Detach topic
export function detachServiceFromTopic(projectId: string, menuId:string, topicId: string, serviceId: string): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}/service/detach/${serviceId}`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return {} as [];
        });
}

export function attachServiceToTopic(projectId: string, menuId:string, topicId: string, serviceId: string): Promise<CbService[]> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}/topic/${topicId}/service/attach/${serviceId}`

    return axios.put(url, "", {headers})
        .then(response => {
            console.log(response.data);
            return response.data as CbService[];
        })
        .catch(error => {
            console.error(error);
            return {} as [];
        });
}


// export function addActionToService(projectId: string, serviceId: string, node: Node, start_end: string): Promise<Item> {
//     const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
//     const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
//     const url = service_url + `/modules/chatboostr/${projectId}/service/${serviceId}/action?action_type=${start_end}`
//
//     return axios.post(url, node, {headers})
//         .then(response => {
//             console.log(response.data);
//             return response.data as Item;
//         })
//         .catch(error => {
//             console.error(error);
//             return {} as Item;
//         });
// }


export function deleteItem(projectId: string, itemId: string, delete_children: boolean): Promise<string> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/item/${itemId}?delete_children=${delete_children}`

    return axios.delete(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as string;
        })
        .catch(error => {
            console.error(error);
            return "";
        });
}

export function UpdateItemSequence(projectId: string, itemId: string, sequence: number): Promise<string> {
    const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/chatboostr/${projectId}/item/${itemId}?sequence=${sequence}`

    return axios.get(url, {headers})
        .then(response => {
            console.log(response.data);
            return response.data as string;
        })
        .catch(error => {
            console.error(error);
            return "";
        });
}
