import React, {useEffect, useState} from "react";
import {
    Box, Grid, MenuItem, Select, TextField
} from "@mui/material";


interface ActionBoxProps {
    redrawTrigger: number;
    selectedAction: string;
    action: string;
    onActionUpdate: (action: string) => void;
    onSelectedActionUpdate: (selectedAction: string) => void;
    mode: string;
}

export default function ActionBox(props: ActionBoxProps) {
    const [selectedAction, setSelectedAction] = useState(props.selectedAction);
    const [action, setAction] = useState(props.action);
    const [mode, setMode] = useState(props.mode);

    useEffect(() => {
        props.onActionUpdate(action);
    }, [action])

    useEffect(() => {
        props.onSelectedActionUpdate(selectedAction);
    }, [selectedAction])

    useEffect(() => {
        setAction(props.action);
        setSelectedAction(props.selectedAction);
    }, [props.redrawTrigger])

    return (
        <Box>
            {/* -------- ACTION BEFORE TYPE and SAVE BUTTON --------- */}
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <Select
                        labelId="select_action_before"
                        size="small"
                        value={selectedAction}
                        id="select_action_before"
                        label="Action Type"
                        onChange={(event) => {
                            const selectedValue = event.target.value;
                            if (selectedValue !== '') {
                                setSelectedAction(selectedValue);
                            }
                        }}
                    >
                        <MenuItem key={""} value={""}>None</MenuItem>
                        <MenuItem key={"template"} value={"template"}>Template</MenuItem>
                        <MenuItem key={"prompt"} value={"prompt"}>Prompt</MenuItem>
                    </Select>
                </Grid>
            </Grid>

            {/* -------- ACTION BEFORE TEXT FIELD --------- */}
            <Grid>
                <TextField
                    fullWidth
                    label="Action"
                    id="action_before"
                    multiline
                    rows={15}
                    value={action}
                    sx={{marginTop: '10px'}}
                    disabled={mode === 'view'}
                    onChange={(event) => setAction(event.target.value)}
                />
            </Grid>
        </Box>
    )
}
