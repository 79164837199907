import React, {useEffect, useState} from "react";
import {
    ExtDataPage,
    getExtPageCount,
    getExtPageData,
    getExtPageImage,
    runGPTRequest
} from "../../backend/extdata.services";
import {languageOptions, Option} from "./ListExtDataRecords";
import {DraggablePaper} from "../general/Styling.snippets";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    Pagination,
    TextField,
    Typography,
    Box
} from "@mui/material";
import {ZoomableImage} from "./SupportingElements";

import {colorSchema} from "../../data/theme";
import {SelectWithData} from "../general/Site.snippets";

interface ExtDataPageViewerProps {
    projectId: string;
    filename: string;
    onClose: () => void;
}

interface PageData {
    pageNumber: number;
    processed: string;
}

function ExtDataPageViewer(props: ExtDataPageViewerProps) {
    const [open, setOpen] = useState(true);
    const [pageNumber, setPageNumber] = useState(-1);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [filename, setFilename] = useState(props.filename);
    const [pageData, setPageData] = useState<ExtDataPage | null>(null);
    const [requestText, setRequestText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [image, setImage] = useState<Uint8Array | undefined>(undefined);
    const [switch_between_original_and_processed, setSwitch_between_original_and_processed] = useState(true);
    const [showActionResult, setShowActionResult] = useState(false);
    const [actionResult, setActionResult] = useState<string[]>([]);
    const [actionResultComplete, setActionResultComplete] = useState(false);
    const [language, setLanguage] = useState(
        localStorage.getItem('settings_language') || languageOptions[0].value)

    useEffect(() => {
        getExtPageCount(props.projectId, filename).then((response) => {
            setTotalNumberOfPages(response);
            setPageNumber(0);
            console.log("Total number of pages: " + response)
        });
    }, []);

    useEffect(() => {
        if (pageNumber >= 0) {

            // Get page data
            getExtPageData(props.projectId, filename, pageNumber).then((response) => {
                if (response === undefined) {
                    return;
                }
                setPageData(response);
                console.log("Page data: " + response.original);

                // Get image
                getExtPageImage(props.projectId, filename, pageNumber).then((response) => {
                    if (response === undefined) {
                        return;
                    }
                    setImage(response.image);
                });
            });
        }
    }, [pageNumber]);

    const handleLanguageSelected = (selected: Option) => {
        setLanguage(selected.value);
        localStorage.setItem('settings_language', selected.value);
    };


    const handlePreviousPage = () => {
        if (pageNumber > 0) {
            setPageNumber((prevPageNumber) => prevPageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < totalNumberOfPages) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };

    const handleSubmit = async () => {
        if (pageData) {
            setActionResult([]);
            runGPTRequest(props.projectId, "", language, pageData.original, prompt, onDataReceived).then(() => {
                setActionResultComplete(true)
            })
            setShowActionResult(true);
        }
    }

    let previousWord = "";
    const onDataReceived = (word: string) => {

        console.log("Received word: " + word);
        console.log("Previous word: " + previousWord);

        // force new line after full stop
        if (previousWord === '. ' && word.charAt(0) === word.charAt(0).toUpperCase()) {
            console.log("Full stop detected");
            word = '\n' + word;
            setActionResult((prevResult) => [...prevResult, ""]);
            previousWord = "";
        } else {
            previousWord = word;
        }

        if (word === '\n') {
            // If the received word is a newline character, add a new empty line to the result
            setActionResult((prevResult) => [...prevResult, ""]);
        } else {
            // Otherwise, append the received word to the last line of the action result
            setActionResult((prevResult) => {
                if (prevResult.length === 0) {
                    // If actionResult is empty, initialize it with an empty string
                    return [word];
                } else {
                    const newResult = [...prevResult];
                    newResult[newResult.length - 1] = newResult[newResult.length - 1] + word;
                    return newResult;
                }
            });
        }
    };


    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} sx={{cursor: 'move', textAlign: 'center'}} color={colorSchema.primary}
                         id="draggable-dialog-title">
                Manage Resource Pages
            </DialogTitle>

            <DialogContentText sx={{mx: 5, my: 3}}>
            </DialogContentText>
            <DialogContent>

                <Grid container>
                    <Grid item xs={5} textAlign={"center"}>
                        {/*{image && <img src={`data:image/png;base64,${image}`}*/}
                        {/*               style={{*/}
                        {/*                   border: '1px solid gray',*/}
                        {/*                   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',*/}
                        {/*                   padding: '5px',*/}
                        {/*                   borderRadius: '4px',*/}
                        {/*                   maxWidth: '100%',*/}
                        {/*                   height: 'auto',*/}
                        {/*                   display: 'inline-block'*/}
                        {/*               }}*/}
                        {/*               alt="Your Image"/>}*/}
                        <Box textAlign={"left"}>
                            <SelectWithData data={languageOptions} id="SelectLanguage" onSelect={handleLanguageSelected}
                                            selected={languageOptions.findIndex((option) => option.value === language)}
                                            label={"Language"} isOpen={false} sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                m: 1,
                                maxHeight: "30px",
                                color: colorSchema.color1,
                            }}/>
                            {/*<Button variant={"outlined"} onClick={() => {*/}
                            {/*    language == 'en' ? setLanguage('nl') : setLanguage('en')*/}
                            {/*}}>{language == 'en' ? 'EN' : 'NL'}</Button>*/}
                        </Box>


                        <Box sx={{marginTop: "25px"}}>
                            <ZoomableImage image={image}/>
                        </Box>

                        <Typography variant={"h6"} marginBottom={3}
                                    color={colorSchema.color2}>Page {pageNumber + 1} of {totalNumberOfPages}</Typography>
                        <Pagination count={totalNumberOfPages} color="primary" page={pageNumber + 1}
                                    onChange={(event, value) => setPageNumber(value - 1)} variant="outlined" size={"small"}
                                    sx={{
                                        '.MuiPagination-ul': {
                                            display: 'inline-flex',
                                        },
                                    }}/>
                    </Grid>
                    <Grid item xs={6} sx={{marginLeft: "10px"}}>
                        <Box>

                            {pageData && (
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '5px',
                                            marginBottom: '5px'
                                        }}
                                    >
                                        <Typography variant="h6" marginBottom={3} color={colorSchema.color2}>
                                            {switch_between_original_and_processed
                                                ? 'Page Text'
                                                : 'Processed Text'}
                                        </Typography>
                                        <Button
                                            sx={{marginLeft: 'auto'}}
                                            variant="contained"
                                            color="success"
                                            onClick={() => {
                                                setSwitch_between_original_and_processed(
                                                    !switch_between_original_and_processed
                                                );
                                            }}
                                        >
                                            {switch_between_original_and_processed ? 'Processed' : 'Original'}
                                        </Button>
                                    </Box>

                                    {switch_between_original_and_processed && (
                                        <Box>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={10}
                                                label="original"
                                                id="original"
                                                value={pageData.original}
                                                disabled={true}
                                            />
                                        </Box>
                                    )}
                                    {!switch_between_original_and_processed && (
                                        <Box>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={10}
                                                label="processed"
                                                id="processed"
                                                value={pageData.processed}
                                                disabled={false}
                                            />
                                        </Box>
                                    )}

                                    <Box>
                                        <Typography variant={"h6"} marginTop={3} marginBottom={2}
                                                    color={colorSchema.color2}>Is there
                                            anything specific you would like to learn
                                            about the content on this page?</Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            label="prompt"
                                            id="prompt"
                                            value={prompt}
                                            onChange={(e) => setPrompt(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Grid>

                </Grid>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleSubmit} disabled={prompt.trim().length === 0}>Execute
                        Prompt</Button>
                    <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </DialogContent>
            {/* -----------------------------------------------------------------------------  */}
            {/* Show the chatGPT request feedback                                              */}
            {/* -----------------------------------------------------------------------------  */}
            {showActionResult && (
                <Dialog
                    open={showActionResult}
                    onClose={() => {
                        setShowActionResult(false)
                    }}
                    PaperComponent={DraggablePaper}
                    sx={{
                        left: '100px',
                        top: '10px',
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle variant={"h4"} id="alert-dialog-title"
                                 sx={{
                                     borderBottom: "1px solid black",
                                     marginBottom: "20px",
                                     color: colorSchema.color3
                                 }}>
                        Accessing the knowledge base to obtain information
                    </DialogTitle>
                    <DialogContent>
                        {actionResult.map((line, index) => {
                            if (line.trim() === '') {
                                return <br key={index}/>;
                            }
                            return (
                                <Typography sx={{marginBottom: '2px', textAlign: 'justify'}} key={index}>
                                    {line}
                                </Typography>
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        {actionResultComplete && (
                            <Button color={"warning"} variant={"contained"} onClick={() => {
                                setShowActionResult(false)
                            }}>Return</Button>
                        )}
                    </DialogActions>
                </Dialog>
            )
            }
        </Dialog>
    )
}

export default ExtDataPageViewer;