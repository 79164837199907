import React, { useState, useEffect, useRef } from 'react';
import { DraggablePaper } from "../general/Styling.snippets";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Snackbar,
    Grid, Typography
} from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { colorSchema } from "../../data/theme";
import {AddUpdateParameter} from "../../backend/extdata.services";

export interface SynonymRecord {
    input: string;
    output: string;
}

interface SynonymListProps {
    open: boolean;
    synonyms: SynonymRecord[];
    onCancel: () => void;
    onSave: (items: SynonymRecord[]) => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SynonymList = (props: SynonymListProps) => {
    const [items, setItems] = useState<SynonymRecord[]>(props.synonyms || []);
    const [newInput, setNewInput] = useState('');
    const [newOutput, setNewOutput] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.open) {
            inputRef.current?.focus();
        }
    }, [props.open]);

    const handleAddItem = () => {
        if (!newInput || !newOutput) {
            setSnackbarMessage('Both input and output are required.');
            setSnackbarOpen(true);
            return;
        }

        if (items.some(item => item.input === newInput || item.output === newOutput)) {
            setSnackbarMessage('Duplicate input or output not allowed.');
            setSnackbarOpen(true);
            return;
        }

        setItems([...items, { input: newInput, output: newOutput }]);
        setNewInput('');
        setNewOutput('');
        setSnackbarMessage('Item added successfully.');
        setSnackbarOpen(true);

        inputRef.current?.focus();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleAddItem();
    };

    const handleEditItem = (index: number, key: keyof SynonymRecord, value: string) => {
        const updatedItems = [...items];
        updatedItems[index][key] = value;
        setItems(updatedItems);
    };

    const handleDelete = (index: number) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this item?');
        if (!confirmDelete) return;

        setItems(items.filter((_, itemIndex) => itemIndex !== index));
        setSnackbarMessage('Item deleted successfully.');
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleSave = () => {
        props.onSave(items);
        setSnackbarMessage('Changes saved successfully.');
        setSnackbarOpen(true);
    };

    const handleCancel = () => {
        props.onCancel();
    };

     return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h4"} sx={{ cursor: 'move', textAlign: 'center' }} color={colorSchema.primary} id="draggable-dialog-title">
                Manage Synonyms
            </DialogTitle>

            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <TextField
                                fullWidth
                                label="Input"
                                size="small"
                                ref={inputRef}
                                value={newInput}
                                onChange={(e) => setNewInput(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                label="Output"
                                size="small"
                                value={newOutput}
                                onChange={(e) => setNewOutput(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Button type="submit" size="small" color="primary">Add</Button>
                        </Grid>
                    </Grid>

                    <Table size="small" sx={{marginTop: '5px'}}>
                        <TableHead>
                            <TableRow>
                                   <TableCell>
                                <Typography variant="subtitle1">Input</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">Output</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">Actions</Typography>
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => (
                               <TableRow key={index}>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={item.input}
                                            onChange={(e) => handleEditItem(index, 'input', e.target.value)}
                                            style={{ border: 'none', width: '100%' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={item.output}
                                            onChange={(e) => handleEditItem(index, 'output', e.target.value)}
                                            style={{ border: 'none', width: '100%' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button size="small" color="primary" onClick={() => handleDelete(index)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                      <Box display="flex" justifyContent="flex-end" marginTop={2}>
                        <Button size="small" sx={{ marginRight: '10px' }} variant="contained" color="primary" onClick={handleSave}>Save</Button>
                        <Button size="small" variant="contained" color="warning" onClick={handleCancel}>Cancel</Button>
                    </Box>
                </form>
            </DialogContent>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default SynonymList;
