import React, {useEffect, useState} from 'react';
import {Container, Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {RecruitmentApp} from "../components/recruitment/RecruitmentApp";
import {
    addJobDescription, Candidate, deleteCandidate,
    deleteJobDescription, getCandidateList, getJobDescriptionList,
    JobDescription, registerCandidate, updateCandidate,
    updateJobDescription
} from "../backend/recruitement.services";

import {colorSchema} from "../data/theme";


interface CustomerDataPageProps {
    message: string;
}

// Recruit MAIN page
const TalentBoostrPage = (props: CustomerDataPageProps) => {

    const [projectName, setProjectName] = useState<string>('');
    const [projectUUID, setProjectUUID] = useState<string>('');

    const [isLoaded, setIsLoaded] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [searchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = useState(0);

    const [jobDescriptionList, setJobDescriptionList] = useState<JobDescription[]>([]);
    const [candidateList, setCandidateList] = useState<Candidate[]>([]);


    useEffect(() => {
        console.log("Loading Recruitment")
        const project_uuid = searchParams.get('uuid') || ""

        setProjectName(searchParams.get('project_name') || "");
        setProjectUUID(project_uuid);

        loadData(project_uuid)

    }, []);

    // Reload data when the status isLoaded changes
    useEffect(() => {
        console.log(`Refresh recruitment data: Refresh state : ${reloadData}`)

        if (reloadData) {
            loadData(projectUUID)
        }
    }, [reloadData])


    function loadData(project_uuid: string) {
        setIsLoaded(false);
        Promise.all([getJobDescriptionList(project_uuid), getCandidateList(project_uuid)])
            .then(([jobDescriptions, candidates]) => {
                setJobDescriptionList(jobDescriptions);
                setCandidateList(candidates);
                setIsLoaded(true);
            });
    }

    function handleRefresh(data_type: string, tab: number) {
        setCurrentTab(tab);
        if (data_type === 'job_description') {
            setIsLoaded(false);
            getJobDescriptionList(projectUUID).then(
                data => {
                    setJobDescriptionList(data);
                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(false);
            getCandidateList(projectUUID).then(
                data => {
                    setCandidateList(data);
                    setIsLoaded(true);
                });
        }
    }

    // Send add JobDescription to backend server
    function handleJobDescriptionAdded(data_type: string, data: JobDescription) {
        data.uuid = ""
        setIsLoaded(false)
        setReloadData(false)

        addJobDescription(projectUUID, data).then(() => {
            setReloadData(true)
            setCurrentTab(0);
        })
    }

    // Send add Candidate to backend server
    function handleCandidateAdded(title: string, file: File | null) {
        setIsLoaded(false)
        setReloadData(false)

        if (file != null) {
            registerCandidate(projectUUID, title, file).then(() => {
                setReloadData(true)
                setCurrentTab(1);
                // Wat zijn de intrieke waarden van bovenstaande profiel?
                // => Bovenstaande request kunnen we op het processes veld doorvoeren.
            })
        } else {
            setIsLoaded(true);
        }
    }


    // Send update request to the backend server.
    function handleUpdate(data_type: string, data: JobDescription | Candidate) {
        setIsLoaded(false)
        setReloadData(false)
        if (data_type === 'job_description') {
            updateJobDescription(projectUUID, data).then(() => {
                setReloadData(true)
                setCurrentTab(0);
            })
        } else if (data_type === 'candidate') {
            updateCandidate(projectUUID, data).then(() => {
                setReloadData(true)
                setCurrentTab(1);
            })
        } else {
            console.log(`HandleUpdate error : datatype: ${data_type} data: ${data}`)
            setIsLoaded(true);
        }
    }

    // Send delete request to the backend server.
    function handleDelete(data_type: string, record_uuid: string) {
        setIsLoaded(false)
        setReloadData(false)
        if (data_type === 'job_description') {
            deleteJobDescription(projectUUID, record_uuid).then(() => {
                setReloadData(true)
                setCurrentTab(0);
            })
        } else if (data_type === 'candidate') {
            deleteCandidate(projectUUID, record_uuid).then(() => {
                setReloadData(true)
                setCurrentTab(1);
            })
        } else {
            console.log(`HandleDelete error : datatype: ${data_type} uuid: ${record_uuid}`)
            setIsLoaded(true);
        }
    }

    return (
        <>
            {/*<Container maxWidth="xl" sx={{minWidth: '100%', opacity:1, maxHeight: '40px'}}>*/}
            {/*        <img src={background} alt="background"/>*/}
            {/*</Container>*/}
            <Container maxWidth="xl" sx={{marginTop: '30px'}}>
                <Typography variant={"h3"} sx={{
                    color: colorSchema.primary,
                    textAlign: "center",
                    marginTop: -2,
                    marginBottom: -2
                }}>Talent</Typography>
                {isLoaded && (
                    <RecruitmentApp projectId={projectUUID} initialTab={currentTab}
                                    candidateList={candidateList}
                                    jobDescriptionList={jobDescriptionList}
                                    onJobDescriptionAdded={handleJobDescriptionAdded}
                                    onCandidateAdded={handleCandidateAdded} onDeleted={handleDelete}
                                    onUpdate={handleUpdate} onRefresh={handleRefresh}/>)}
            </Container>
        </>
    );
}


export default TalentBoostrPage;